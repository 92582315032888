<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title has-text-weight-semibold is-size-3 ">{{ edit === false ? 'Create a new' : 'Edit' }}
          Lesson Plan</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field expanded label="Syllabus">
          <span
              class="is-size-4 control">           {{ course_scope.learning_stream.name }} {{
              course_scope.syllabus
            }}
</span>
        </b-field>
        <b-field expanded label="Code">
          {{ course_scope.code }}
        </b-field>
        <b-field expanded label="Examination Year">
          {{ course_scope.examination_year }}
        </b-field>

        <b-field label="Topic" expanded>
          <b-select v-model="lessonObject.course_scope_topic_id" expanded placeholder="Topic"
                    required>
            <option v-for="topic in topics" :key="topic.id" :value="topic.id">{{ topic.name }} |
              {{ topic.reference }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Name" expanded>
          <b-input v-model="lessonObject.name" expanded
                   placeholder="Name">

          </b-input>
        </b-field>

        <b-field label="Time Estimate" expanded>

          <b-timepicker
              v-model="timeEstimate"
              :hour-format="'24'"
              :time-formatter="formatTime"
              append-to-body
              expanded
              placeholder="Time Estimate"
          >
          </b-timepicker>

        </b-field>


        <b-field expanded label="Campus">
          {{ campus.name }}
        </b-field>
        <b-field label="Objective Search Type">
          <b-switch v-model="searchType"
                    true-value="Reference"
                    false-value="Content">
            {{ searchType }}
          </b-switch>
        </b-field>
        <b-field expanded label="Objectives">
          <b-taginput
              ref="ObjectiveTagInput"
              v-model="lessonObject.objectives"
              :allow-duplicates="false"
              :allow-new="false"
              :data="filteredObjectives"
              :icon="$tc('icons.label')"
              :open-on-focus="true"
              autocomplete
              field="reference"
              placeholder="Add an objective"
              @typing="getFilteredObjectives"
              @keydown.enter.native.prevent>
          </b-taginput>
        </b-field>

        <b-field label="Class">
          <b-select v-model="lessonObject.class_group_id" expanded placeholder="Class" required>
            <option v-for="classGroup in classGroups" :key="classGroup.id" :value="classGroup.id">
              {{ classGroup.name }}
            </option>
          </b-select>
        </b-field>

        <!--          <div class="column  ">-->
        <!--            <b-field :label="`Needs ${$tc('Learner',1)} Reflection`">-->
        <!--              <b-switch v-model="lessonObject.needs_learner_reflections"-->
        <!--                        :true-value="1"-->
        <!--                        :false-value="0">-->
        <!--                {{ lessonObject.needs_learner_reflections ? 'Yes' : 'No' }}-->
        <!--              </b-switch>-->
        <!--            </b-field>-->
        <!--          </div>-->

        <b-field expanded label="Share with other educators"
                 message="All educators of this subject will have access to this lesson">
          <b-switch

              v-model="lessonObject.is_global" :false-value="0" :true-value="1">
            {{ lessonObject.is_global ? 'Yes' : 'No' }}
          </b-switch>
        </b-field>


        <div v-if="edit" class="column">
          <b-field group-multiline grouped>
            <b-field>
              <b-button :icon-right="$tc('icons.pdf')" @click.prevent="download">
                Download
              </b-button>
            </b-field>
            <!--            <b-field v-if="lessonObject.needs_learner_reflections===1">-->
            <!--              <b-button @click.prevent="startRequest" :icon-right="$tc('icons.share')">Request {{ $tc('Learner', 1) }} Reflection-->
            <!--              </b-button>-->
            <!--            </b-field>-->
            <b-field>
              <b-button :icon-right="$tc('icons.share')" @click.prevent="startShare">Share With Users</b-button>
            </b-field>

          </b-field>

        </div>
        <b-field v-if="lessonObject.objectives" expanded label="Lesson Objectives:">
          <ObjectiveLineItem v-for="objective in lessonObject.objectives" :key="objective.id"
                             :objective="objective"></ObjectiveLineItem>
        </b-field>
        <div class="mt-6">
          <div class="level mb-2">
            <div class="level-left">
              <p class="level-item has-text-weight-semibold is-size-4"> Starter Activity: </p>
            </div>
          </div>
          <div>

            <div v-if="loaded" style="overflow: hidden">
              <ckeditor
                  v-model="starter_activity_content"
                  :config="activityEditorConfig"

                  :editor="editor"></ckeditor>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <div class="level mb-2">
            <div class="level-left">
              <p class="level-item has-text-weight-semibold is-size-4"> Checkpoint: </p>
            </div>
          </div>
          <div>

            <div v-if="loaded" style="overflow: hidden">
              <ckeditor
                  v-model="checkpoint_content"
                  :config="checkpointEditorConfig"

                  :editor="editor"></ckeditor>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <div class="level mb-2">
            <div class="level-left">
              <p class="level-item has-text-weight-semibold is-size-4"> Engagement: </p>
            </div>
          </div>
          <div>

            <div v-if="loaded" style="overflow: hidden">
              <ckeditor
                  v-model="engagement_content"
                  :config="engagementEditorConfig"

                  :editor="editor"></ckeditor>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <div class="level mb-2">
            <div class="level-left">
              <p class="level-item has-text-weight-semibold is-size-4"> Resources: </p>
            </div>
          </div>
          <div>

            <div v-if="loaded" style="overflow: hidden">
              <ckeditor
                  v-model="resources_content"
                  :config="resourcesEditorConfig"

                  :editor="editor"></ckeditor>
            </div>
          </div>
        </div>
        <p v-if="this.edit" class="has-text-weight-semibold mt-5 is-size-4">Uploads:</p>

        <div v-if="this.edit" class="columns is-multiline">
          <b-field class="column is-12">
            <div class="columns is-centered">
              <div class="column is-12 has-text-centered">
                <b-upload v-model="dropFile" :disabled="!this.edit || isLoading" drag-drop>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon
                            :icon="$tc('icons.upload')"
                            size="is-large"
                        ></b-icon>
                      </p>
                      <p>Drop your file here or click to upload</p>
                      <p>Max file size of 10mb</p>
                    </div>
                  </section>
                </b-upload>
              </div>
            </div>
          </b-field>
          <div v-if="dropFile" class="upload-file-list column is-12">
            <div class="notification">
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <form @submit.prevent="submitMedia">
                      <p class="is-flex is-align-items-center">
                        <b-icon
                            custom-size="default"
                            :icon="$tc('icons.upload')"
                        ></b-icon>
                        <span>{{ dropFile.name }}</span></p>
                      <b-field>
                        <b-field>
                          <b-input
                              v-model="dropFile.caption"
                              name="caption"
                              placeholder="Describe the upload"
                          />
                        </b-field>
                      </b-field>

                      <b-field>
                        <b-field grouped>
                          <div class="control">
                            <b-button
                                native-type="submit"
                                type="is-primary"
                            >Submit upload
                            </b-button
                            >
                          </div>
                        </b-field>
                      </b-field>
                    </form>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <a
                        class="tag is-delete"
                        @click.prevent="deleteDropFile()"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-table
            v-if="this.edit"
            :bordered="true"
            :data="media"
            :hoverable="true"
            :striped="true"
            class="margin-top"
        >
          <template #empty>
            <div class="has-text-centered">No Media</div>
          </template>
          <b-table-column v-slot="props"

                          field="filename"
                          label="Filename"
                          sortable
          >{{ props.row.filename }}
          </b-table-column
          >


          <b-table-column v-slot="props"
                          field="caption"
                          label="Caption"
                          sortable
          >{{
              props.row.caption
            }}
          </b-table-column
          >

          <b-table-column v-slot="props" custom-key="actions" width="170">
            <b-field grouped>
              <b-field>
                <b-button
                    :href="props.row.temporary_url"
                    tag="a"
                    target="_blank"
                    type="is-primary">View
                </b-button>

              </b-field>
              <b-field style="height: fit-content; margin: auto">
                <b-tooltip
                    label="Delete"
                    size="is-small"
                    type="is-danger">
                  <a
                      class="card-header-icon has-text-danger px-0 py-0"
                      @click.prevent.stop="startDelete(props.row)">
                    <b-icon :icon="$tc('icons.delete')"/>
                  </a>
                </b-tooltip>
              </b-field>
            </b-field>

          </b-table-column>
        </b-table>


        <b-loading v-model="isLoading" :can-cancel="false" :is-full-page="false"></b-loading>
        <b-field v-if="!inModal" class="has-text-centered">
          <b-button native-type="submit" type="is-primary">Save</b-button>
        </b-field>
      </section>
      <footer v-if="inModal" :class="{'modal-card-foot':inModal}">

        <b-field grouped position="is-centered" style="width: 100%;">
          <b-field position="is-centered">
            <b-button @click.prevent="$emit('close')">Cancel</b-button>
          </b-field>
          <b-field position="is-centered">
            <b-button :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)" native-type="submit"
                      type="is-primary">Save
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
  </form>
</template>

<script>
import CourseScope from "@/models/CourseScope";
import Objective from "@/models/Objective";
import Topic from "@/models/Topic";
import Campus from "@/models/Campus";
import {format, hoursToMinutes} from "date-fns";
import LessonPlan from "@/models/LessonPlan";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {mathEditorConfig} from "@/mathEditorConfig";
import clip from "text-clipper";
import Media from "@/models/Media";
import ObjectiveLineItem from "@/components/lessonPlans/ObjectiveLineItem";
import LearnerShareForm from "@/components/lessonPlans/LearnerShareForm";
import LessonShareForm from "@/components/lessonPlans/LessonShareForm";
import ClassGroup from "@/models/ClassGroup";

export default {
  name: "LessonPlanForm", components: {ObjectiveLineItem},

  data() {
    return {
      editor: ClassicEditor,
      emptyEditorConfig: {
        ...{plugins: mathEditorConfig.plugins, toolbar: []},
        ...{placeholder: "Objective body"},

      },
      activityEditorConfig: {
        ...mathEditorConfig,
        ...{placeholder: "Starter Activity"},
      }, engagementEditorConfig: {
        ...mathEditorConfig,
        ...{placeholder: "Engagement"},
      }, checkpointEditorConfig: {
        ...mathEditorConfig,
        ...{placeholder: "Plenary & Checkpoint"},
      }, resourcesEditorConfig: {
        ...mathEditorConfig,
        ...{placeholder: "Resources"},
      },
      dropFile: null,
      isLoading: false,
      loaded: false,
      filteredObjectives: [],
      expandedObjectives: [],
      searchType: 'Reference',
      lessonObject: {
        course_scope_id: this.course_scope_id,
        course_scope_topic_id: null,
        campus_id: this.campus_id,
        learning_stream_id: null,
        objectives: [],
        objective_ids: [],
        starter_activity: '',
        is_global: 0,
        checkpoint: '',
        engagement: '',
        resources: '',
        needs_learner_reflections: 0,
        class_group_id: this.$store.state.campus.selected_class_group_id,
        time_estimate: null,
        subject_id: this.subject_id


      }
    }
  },
  computed: {
    timeEstimate: {
      set(newVal) {
        this.lessonObject.time_estimate = hoursToMinutes(newVal.getHours()) + newVal.getMinutes()
      },
      get() {
        if (this.lessonObject.time_estimate == null) {
          return null;
        }
        const hours = Math.floor(this.lessonObject.time_estimate / 60)
        const minutes = this.lessonObject.time_estimate % 60
        return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), hours, minutes)
      },
    },
    classGroups() {
      return ClassGroup.query().get()
    },
    media() {
      return Media.query().get()
    },
    starter_activity_content: {
      get() {
        if (!this.expandedObjectives.includes('starter')) {
          return this.clipDescription(this.lessonObject.starter_activity)
        }
        return this.lessonObject.starter_activity
      },
      set(newVal) {
        if (!this.expandedObjectives.includes('starter')) {
          this.toggleExpand('starter')
        }
        this.lessonObject.starter_activity = newVal

      }
    },
    checkpoint_content: {
      get() {
        if (!this.expandedObjectives.includes('checkpoint')) {
          return this.clipDescription(this.lessonObject.checkpoint)
        }
        return this.lessonObject.checkpoint
      },
      set(newVal) {
        if (!this.expandedObjectives.includes('checkpoint')) {
          this.toggleExpand('checkpoint')
        }
        this.lessonObject.checkpoint = newVal

      }
    }, resources_content: {
      get() {
        if (!this.expandedObjectives.includes('resources')) {
          return this.clipDescription(this.lessonObject.resources)
        }
        return this.lessonObject.resources
      },
      set(newVal) {
        if (!this.expandedObjectives.includes('resources')) {
          this.toggleExpand('resources')
        }
        this.lessonObject.resources = newVal

      }
    }, engagement_content: {
      get() {
        if (!this.expandedObjectives.includes('engagement')) {
          return this.clipDescription(this.lessonObject.engagement)
        }
        return this.lessonObject.engagement
      },
      set(newVal) {
        if (!this.expandedObjectives.includes('engagement')) {
          this.toggleExpand('engagement')
        }
        this.lessonObject.engagement = newVal

      }
    },
    plan_date: {
      get() {
        return new Date(this.lessonObject.lesson_date)
      }, set(newVal) {
        this.lessonObject.lesson_date = newVal
      }
    },
    course_scope() {
      return CourseScope.query().whereId(this.course_scope_id).with('learning_stream').first()
    },
    campus() {
      return Campus.query().whereId(this.lessonObject.campus_id).first()
    },
    topics() {
      return Topic.query().where('course_scope_id', this.course_scope.id).orderBy('order').get()
    },
    objectives() {
      return Objective.query().orderBy('reference').get()
    },

  },
  methods: {
    formatTime(time) {
      return format(time, 'HH:mm')
    },
    startShare() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          lesson_plan_id: this.lessonObject.id,
          campus_id: this.lessonObject.campus_id
        },
        component: LessonShareForm,
        fullScreen: false,
        trapFocus: true,
      });
    }, startRequest() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          lesson_plan_id: this.lessonObject.id
        },
        component: LearnerShareForm,
        fullScreen: false,
        trapFocus: true,
      });
    },
    download() {
      this.$store.dispatch('loader/show')
      LessonPlan.Download(this.lessonPlan.id).then(({response}) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `lesson-plan.pdf`
        );

        document.body.appendChild(link);
        link.click();
        this.$store.dispatch('loader/hide')

      }).catch((err) => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    deleteDropFile() {
      this.dropFile = null
    },
    delete(media) {
      this.isLoading = true
      Media.Delete(media.id).then(() => {
        this.$buefy.snackbar.open('Resource deleted!')
        this.isLoading = false
      }).catch(
          err => {
            this.isLoading = false;

            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      )
    },
    startDelete(media) {
      this.$buefy.dialog.confirm({
        title: 'Deleting resource',
        confirmText: 'Delete Resource',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this resource?',
        onConfirm: () => this.delete(media)
      })
    },
    submitMedia() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.dropFile);
      formData.append("caption", this.dropFile.caption);

      Media.Store({model: 'lesson_plans', model_id: this.lessonPlan.id}, formData)
          .then(() => {
            this.dropFile = null
            this.isLoading = false
            // Media.FetchAll(this.application_details.id, "applications").then(
            //     () => {
            //       this.dropFile = null;
            //       this.isLoading = false;
            //     }
            // );
          }).catch(
          err => {
            this.isLoading = false;

            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      )
    },
    toggleExpand(id) {
      if (this.expandedObjectives.includes(id)) {
        this.expandedObjectives = this.expandedObjectives.filter(objective => {
          return objective !== id
        })
      } else {
        this.expandedObjectives.push(id)
      }
    },
    clipDescription(text) {
      return clip(text, 300, {
        html: true,
      });
    },
    getFilteredObjectives(text) {
      let searchKeys = this.searchType === 'Reference' ? ['reference'] : ['name']
      if (Objective.query().where(searchKeys[0],text).exists()){
        this.filteredObjectives = Objective.query().where(searchKeys[0],text).get()
        return
      }
      this.filteredObjectives = Objective.query().search(text, {
        caseSensitive: false,
        threshold: 0.3,
        keys: searchKeys,
        tokenize: true,
        findAllMatches: true,
        shouldSort: true
      }).get()
    },
    submit() {
      let submitObject = JSON.parse(JSON.stringify(this.lessonObject))
      submitObject.objective_ids = []
      submitObject.objectives.map(item => {
        submitObject.objective_ids.push(item.id)
      })
      submitObject.objectives = submitObject.objective_ids
      delete submitObject.objective_ids
      delete submitObject.course_scope
      if (this.edit) {
        this.isLoading = true
        LessonPlan.Update(submitObject, ['objectives']).then(() => {
          this.isLoading = false
          this.$buefy.snackbar.open(`Lesson Plan updated!`)
          this.$emit('close')
        }).catch(err => {
          this.isLoading = false
          this.handleError(err)
        })
      } else {
        this.isLoading = true
        LessonPlan.Store(submitObject, ['objectives']).then(() => {
          this.isLoading = false
          this.$buefy.snackbar.open(`Lesson Plan created!`)
          this.$emit('close')
        }).catch(err => {
          this.isLoading = false
          this.handleError(err)
        })
      }
    }
  },
  watch: {
    searchType(){
      this.$refs.ObjectiveTagInput.newTag = null
      this.filteredObjectives = this.objectives
    },
    course_scope_topic_id(newVal) {
      if (newVal !== null) {
        this.isLoading = true
        Objective.deleteAll()
        Topic.FetchById(newVal, ['units', 'units.objectives']).then(() => {
          this.isLoading = false
        }).catch(err => {
          this.handleError(err)
          this.isLoading = false
        })
      }
    }
  },
  mounted() {
    if (this.inModal) {
      this.makeModalLarge()
    }
    this.$store.dispatch('loader/hide')

    this.isLoading = true
    let promises = [CourseScope.FetchById(this.course_scope.id, ['topics', 'learning_stream'])]
    Media.deleteAll()
    if (this.edit) {
      Objective.deleteAll()
      promises.push(Topic.FetchById(this.lessonPlan.course_scope_topic_id, ['units', 'units.objectives']))
      promises.push(Media.FetchAll({page: 1, limit: 30}, {modelName: 'lesson_plans', model: this.lessonPlan.id}))

    }
    Promise.all([promises]).then(() => {
      if (this.edit) {
        this.lessonObject = this.lessonPlan
      } else {
        this.timeEstimate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0)
      }
      this.isLoading = false
      this.loaded = true
      this.filteredObjectives = this.objectives

    })


  },
  props: {
    defaultDate: {
      type: Date,
      default() {
        return format(new Date(), 'yyyy-MM-dd')
      }
    },
    lessonPlan: {
      type: Object,
      required: false
    },
    course_scope_id: {
      type: Number,
      required: true
    },
    campus_id: {
      type: Number, default() {
        return 0
      }
    }, subject_id: {
      type: Number, default() {
        return null
      }
    },

    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

