<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Assessment</p>
      </header>
      <PerfectScrollbarWrapper>
        <section :class="{'modal-card-body':inModal}">
          <b-field expanded label="Assessment Type">
            <b-select expanded required v-model="assessmentObject.type_id">
              <option :value="1">Task</option>
              <option :value="2">Project</option>
              <option :value="3">Formal</option>
              <option :value="4">Exam</option>
            </b-select>

          </b-field>

          <b-field expanded label="Name">
            <b-input
                v-model="assessmentObject.name"
                placeholder="Assessment name"
                expanded
                required>
            </b-input>
          </b-field>
          <b-field expanded label="Subject">
            <b-select required expanded placeholder="Subject" v-model="assessmentObject.subject_id">
              <option v-for="subject in subjects" :key="subject.id" :value="subject.id">{{subject.id}} - {{subject.long_name}}</option>
            </b-select>
          </b-field>
          <b-field  expanded label="Class">
            <b-select :loading="loadingClassGroups" placeholder="Class" v-model="assessmentObject.class_group_id" expanded required>
              <option v-for="classGroup in classGroups" :key="classGroup.id" :value="classGroup.id">
                {{ classGroup.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Creator">
            <UsersFilter
                :disabled="true"
                :selected_user_id="assessmentObject.teacher_id"
                :type="'autocomplete'"
            ></UsersFilter>
          </b-field>
          <b-field expanded label="Year">
            <b-numberinput expanded required v-model="year" min="2020" step="1">

            </b-numberinput>
          </b-field>
          <b-field expanded label="Term">
            <b-select required expanded v-model="assessmentObject.term_id">
              <optgroup v-for="semester in termsData" :key="semester.id" :label="semester.name">
                <option v-for="term in semester.children" :key="term.id" :value="term.id">{{ term.name }}</option>
              </optgroup>
            </b-select>
          </b-field>
          <b-field expanded label="Assessment date">
            <b-datepicker
                :years-range="[-80,80]" placeholder="Date of assessment" required expanded
                v-model="assessment_date"></b-datepicker>
          </b-field>

          <b-field label="Total marks" expanded>
            <b-numberinput placeholder="Marks" required expanded v-model="assessmentObject.total_marks" min="0"
                          step="1"></b-numberinput>
          </b-field>
          <b-field expanded label="Share with other educators"
                  message="All educators of this subject will be required to run this assessment">
            <b-switch

                v-model="assessmentObject.is_global" :true-value="1" :false-value="0">
              {{ assessmentObject.is_global ? 'Yes' : 'No' }}
            </b-switch>
          </b-field>
        </section>
      </PerfectScrollbarWrapper>
      <footer :class="{'modal-card-foot':inModal}">
        <button :disabled="edit?!canEdit:!canCreate" class="button"
                type="is-primary"
                :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import Assessment from "@/models/Assessment";
import {format, isValid} from "date-fns";
import Term from "@/models/Term";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import ClassGroup from "@/models/ClassGroup";
import Subject from "@/models/Subject";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "AssessmentForm",
  components: {UsersFilter, PerfectScrollbarWrapper},
  data() {
    return {
      loading: false,
      loadingClassGroups:false,
      year: new Date().getFullYear(),
      assessmentObject: {
        name: '',
        assessment_date: format(new Date(), 'yyyy-MM-dd'),
        term_id: this.term_id,
        campus_id: this.campus_id,
        total_marks: null,
        type_id: null,
        subject_id: this.subject_id,
        teacher_id: this.$store.state.user.id,
        teacher: this.$store.state.user,
        is_global: false,
        class_group_id: null
      }
    }
  },
  watch: {
    'assessmentObject.subject_id'(newValue){
      this.loadingClassGroups = true
      this.assessmentObject.class_group_id = null
      ClassGroup.FetchAll({page:1,limit:999},{subject_id:newValue}).catch(err=>{
        this.handleError(err)
      }).finally(()=>{
        this.loadingClassGroups = false
      })
    },
    year(newValue) {
      if (newValue !== null) {
        if (newValue > 2019) {
          this.getTerms()
        }
      }
    }
  },
  computed: {
    subjects(){
      return Subject.query().get()
    },
    classGroups() {
      return ClassGroup.query().where('subject_id', this.assessmentObject.subject_id).get()
    },
    userCheck() {
      if (this.edit) {
        if (this.assessmentObject.teacher_id !== null) {
          return this.assessmentObject.teacher_id === this.$store.state.user.id
        }
      }
      return true
    },
    termsData() {
      if (this.manageTerms) {
        return Term.query().where('parent_id', null).where(term => {
          return new Date(term.start).getFullYear() === this.year
        }).with('children').orderBy('start').get()
      }
      return this.terms
    },
    assessment_date: {
      get() {
        if (isValid(new Date(this.assessmentObject.assessment_date))) {
          return new Date(this.assessmentObject.assessment_date)
        }
        return null
      }, set(newValue) {
        this.assessmentObject.assessment_date = format(newValue, 'yyyy-MM-dd')
      },
    },
  },
  methods: {
    getTerms() {
      this.loading = true
      return Promise.all([Term.FetchAll(
          {
            page: 1,
            limit: 99,
          },
          {year: this.year, is_parent: 1, campus_id: this.$store.state.campus.selected_campus_id},
          ["children"]
      ), ClassGroup.FetchAll({page: 1, limit: 999}, {subject_id: this.subject_id}),Subject.FetchAll({page:1,limit:999},{campus_id:this.$store.state.campus.selected_campus_id})])
          .catch(err => {
            this.handleError(err)
          }).finally(() => {
            this.loading = false
          });
    },

    submit() {
      if (this.edit) {
        Assessment.Update({
              name: this.assessmentObject.name,
              assessment_date: this.assessmentObject.assessment_date,
              term_id: this.assessmentObject.term_id,
              campus_id: this.assessmentObject.campus_id,
              total_marks: this.assessmentObject.total_marks,
              type_id: this.assessmentObject.type_id,
              subject_id: this.assessmentObject.subject_id,
              id: this.assessmentObject.id,
              is_global: this.assessmentObject.is_global,
              class_group_id: this.assessmentObject.class_group_id

            }
        ).then(() => {
          this.$buefy.snackbar.open(`Assessment updated!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      } else {
        Assessment.Store({
          name: this.assessmentObject.name,
          assessment_date: this.assessmentObject.assessment_date,
          term_id: this.assessmentObject.term_id,
          campus_id: this.assessmentObject.campus_id,
          total_marks: this.assessmentObject.total_marks,
          type_id: this.assessmentObject.type_id,
          subject_id: this.assessmentObject.subject_id,
          id: this.assessmentObject.id,
          is_global: this.assessmentObject.is_global,
          class_group_id: this.assessmentObject.class_group_id


        }).then(() => {
          this.$buefy.snackbar.open(`Assessment created!`)
          this.$emit('close')
        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.assessmentObject = this.assessment
      this.year = new Date(this.assessmentObject.assessment_date).getFullYear()
    } else {
      this.year = this.default_year
    }
    this.getTerms()
  }
  ,
  props: {
    default_year: {
      type: Number,
      default() {
        return new Date().getFullYear()
      }
    },
    manageTerms: {
      type: Boolean,
      default() {
        return false
      }
    },
    terms: {
      type: Array,
      default() {
        return []
      }
    },
    campus_id: {
      type: Number,
      default
          () {
        return 0
      }
    }, subject_id: {
      type: Number,
      required: true

    }, term_id: {
      type: Number,
      default
          () {
        return null
      }
    }, parent_id: {
      type: Number,
      default
          () {
        return null
      }
    },
    assessment:
        {
          type: Object,
          default
              () {
            return {
              name: ''
            }
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }, canEdit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    , canCreate: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

