<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">Request Reflections from {{ $tc('Learner', 2) }}</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field :label="$tc('Learner',2)">
          <b-dropdown expanded v-model="selectedLearners" multiple aria-role="list">
            <b-button expanded
                      class="select" :class="{'is-empty':selectedLearners.length===0}">
              <span>Select {{ $tc('Learner', 2) }}</span>

            </b-button>

            <b-dropdown-item
                v-for="learner in learners" :key="learner.id" :value="learner"
                aria-role="listitem"
            >
              {{
                learner.first_name
              }}
              {{ learner.last_name }}

            </b-dropdown-item>
          </b-dropdown>
        </b-field>


        <b-field :label="`Selected ${$tc('Learner',2)}`">
          <b-table
              class="margin-top"
              :data="selectedLearners"
              :striped="true"
              :hoverable="true"
              :bordered="true"
          >
            <template #empty>
              <div class="has-text-centered">No {{ $tc('Learner', 2) }}</div>
            </template>
            <b-table-column v-slot="props" centered label="Picture">
              <div><p style="width: 48px;height: 48px" class="image is-48x48">
                <img :src="props.row.avatar"
                     :alt="props.row.first_name + ' ' +props.row.last_name + ' ' + 'avatar'"
                     class="is-rounded"
                >
              </p></div>
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                field="first_name"
                label="First Name"
            >{{ props.row.first_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                field="last_name"
                label="Last Name"
            >{{ props.row.last_name }}
            </b-table-column>
            <b-table-column v-slot="props" sortable field="email" label="Email">{{
                props.row.email
              }}
            </b-table-column>
            <b-table-column v-slot="props" narrowed centered custom-key="actions">
              <b-field grouped>
                <b-field>
                  <b-tooltip label="Remove" size="is-small" type="is-danger">
                    <b-button
                        type="is-danger"
                        :icon-right="$tc('icons.minus')"
                        @click="removeLearner(props.row)"
                    ></b-button>
                  </b-tooltip>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>

        </b-field>

      </section>
      <footer v-if="inModal" class="modal-card-foot">
        <button :loading="loading" class="button" type="is-primary"
                :icon-right="'share'">Request
        </button>
      </footer>
      <b-field grouped class="mt-4" v-else>
        <div class="control">
          <b-button :loading="loading" native-type="submit"
                    tag="input"
                    type="submit" value="Save">
          </b-button>
        </div>

      </b-field>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>
import User from "@/models/User"
import LessonPlan from "@/models/LessonPlan";

export default {
  name: "LearnerShareForm",
  data() {
    return {
      loading: false,
      selectedLearners: [],
    }
  },
  methods: {
    removeLearner(learner) {
      this.selectedLearners = this.selectedLearners.filter(user => user.id !== learner.id)
    },
    submit() {
      this.loading = true
      let ids = []
      this.selectedLearners.map(learner => ids.push(learner.id))

      LessonPlan.Request(this.lesson_plan_id, ids).then(() => {
        this.loading = false
        this.$buefy.snackbar.open('Reflections Requested!')
        this.$emit('close')
      }).catch(err => {
        this.handleError(err)
        this.loading = false
      })
    }

  },

  computed: {
    learners() {
      return User.query().orderBy('first_name').get()
    }
  },
  mounted() {
    this.loading = true
    User.deleteAll()

    let promises = [User.FetchLearners(this.lesson_plan_id)]

    Promise.all(promises).then(() => {

      this.loading = false

    }).catch(err => {
      this.handleError(err)
      this.loading = false
    })
  },
  props: {
    lesson_plan_id: {
      type: Number, required: true
    },

    inModal: {
      type: Boolean, default() {
        return true
      }
    },

  }
}
</script>

