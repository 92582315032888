<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">Share Lesson Plan</p>
      </header>
      <section style="min-height: 400px;min-width: 300px" :class="{'modal-card-body':inModal}">
        <b-field label="User filter type">
          <b-select v-model="filterMode">
            <option :value="0">{{ $tc('Learner', 2) }}
            </option>
            <option :value="1">{{ $tc('Guardian', 2) }}
            </option>

            <option :value="2">{{ $tc('Teacher', 2) }}
            </option>
          </b-select>
        </b-field>
        <b-field expanded v-if="filterMode===0" :label="$tc('Learner',1)">
          <b-dropdown expanded v-model="selectedLearners" multiple aria-role="list">
            <b-button expanded class="select" :class="{'is-empty':selectedLearners.length===0}" slot="trigger">
              <span>Select {{ $tc('Learner', 2) }}</span>

            </b-button>

            <b-dropdown-item
                v-for="learner in learners" :key="learner.id" :value="learner"
                aria-role="listitem"
            >
              {{
                learner.first_name
              }}
              {{ learner.last_name }}

            </b-dropdown-item>
          </b-dropdown>
        </b-field>
        <b-field v-if="filterMode===1" :label="`Guardians`">
          <UsersFilter :clear-on-select="true" :role="'guardian'" :dropdown-direction="'down'"
                       :type="'autocomplete'" @selected="selectGuardian"
                       :campus_id="campus_id"></UsersFilter>
        </b-field>
        <b-field v-if="filterMode===2" :label="`${$tc('Teacher',2)}`">
          <UsersFilter :clear-on-select="true" :without_roles="['learner','guardian']" :dropdown-direction="'down'"
                       :type="'autocomplete'" @selected="selectTeacher"
                       :campus_id="campus_id"></UsersFilter>
        </b-field>


        <b-field v-if="selectedLearners.length>0" :label="`Selected ${$tc('Learner',2)}`">
          <PerfectScrollbarWrapper>
          <b-table
              class="margin-top is-fullwidth"
              :data="selectedLearners"
              :striped="true"
              :hoverable="true"
              :bordered="true"
          >
            <template #empty>
              <div class="has-text-centered">No {{ $tc('Learner', 2) }} selected</div>
            </template>
            <b-table-column v-slot="props" centered label="Picture">
              <div><p style="width: 48px;height: 48px" class="image is-48x48">
                <img :src="props.row.avatar"
                     :alt="props.row.first_name + ' ' +props.row.last_name + ' ' + 'avatar'"
                     class="is-rounded"
                >
              </p></div>
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                field="first_name"
                label="First Name"
            >{{ props.row.first_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                field="last_name"
                label="Last Name"
            >{{ props.row.last_name }}
            </b-table-column>
            <b-table-column v-slot="props" sortable field="email" label="Email">{{
                props.row.email
              }}
            </b-table-column>
            <b-table-column v-slot="props" narrowed centered custom-key="actions">
              <b-field grouped>
                <b-field>
                  <b-tooltip label="Remove" size="is-small" type="is-danger">
                    <b-button
                        type="is-danger"
                        :icon-right="$tc('icons.minus')"
                        @click="removeLearner(props.row)"
                    ></b-button>
                  </b-tooltip>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>
          </PerfectScrollbarWrapper>
        </b-field>
        <b-field v-if="selectedGuardians.length>0" :label="`Selected Guardians`">
          <PerfectScrollbarWrapper>
            <b-table
                class="margin-top is-fullwidth"
                :data="selectedGuardians"
                :striped="true"
                :hoverable="true"
                :bordered="true"
            >
              <b-table-column v-slot="props" centered label="Picture">
                <div><p style="width: 48px;height: 48px" class="image is-48x48">
                  <img :src="props.row.avatar"
                       :alt="props.row.first_name + ' ' +props.row.last_name + ' ' + 'avatar'"
                       class="is-rounded"
                  >
                </p></div>
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  sortable
                  field="first_name"
                  label="First Name"
              >{{ props.row.first_name }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  sortable
                  field="last_name"
                  label="Last Name"
              >{{ props.row.last_name }}
              </b-table-column>
              <b-table-column v-slot="props" sortable field="email" label="Email">{{
                  props.row.email
                }}
              </b-table-column>
              <b-table-column v-slot="props" narrowed centered custom-key="actions">
                <b-field grouped>
                  <b-field>
                    <b-tooltip label="Remove" size="is-small" type="is-danger">
                      <b-button
                          type="is-danger"
                          :icon-right="$tc('icons.minus')"
                          @click="removeGuardian(props.row)"
                      ></b-button>
                    </b-tooltip>
                  </b-field>
                </b-field>
              </b-table-column>
            </b-table>
          </PerfectScrollbarWrapper>

        </b-field>
        <b-field v-if="selectedTeachers.length>0" :label="`Selected ${$tc('Teacher',2)}`">
          <PerfectScrollbarWrapper>
            <b-table
                class="margin-top is-fullwidth"
                :data="selectedTeachers"
                :striped="true"
                :hoverable="true"
                :bordered="true"
            >
              <b-table-column v-slot="props" centered label="Picture">
                <div><p style="width: 48px;height: 48px" class="image is-48x48">
                  <img :src="props.row.avatar"
                       :alt="props.row.first_name + ' ' +props.row.last_name + ' ' + 'avatar'"
                       class="is-rounded"
                  >
                </p></div>
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  sortable
                  field="first_name"
                  label="First Name"
              >{{ props.row.first_name }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  sortable
                  field="last_name"
                  label="Last Name"
              >{{ props.row.last_name }}
              </b-table-column>
              <b-table-column v-slot="props" sortable field="email" label="Email">{{
                  props.row.email
                }}
              </b-table-column>
              <b-table-column v-slot="props" narrowed centered custom-key="actions">
                <b-field grouped>
                  <b-field>
                    <b-tooltip label="Remove" size="is-small" type="is-danger">
                      <b-button
                          type="is-danger"
                          :icon-right="$tc('icons.minus')"
                          @click="removeTeacher(props.row)"
                      ></b-button>
                    </b-tooltip>
                  </b-field>
                </b-field>
              </b-table-column>
            </b-table>
          </PerfectScrollbarWrapper>

        </b-field>

      </section>
      <footer v-if="inModal" class="modal-card-foot">
        <button :loading="loading" class="button" type="is-primary"
                :icon-right="'share'">Share
        </button>
      </footer>
      <b-field grouped class="mt-4" v-else>
        <div class="control">
          <b-button :loading="loading" native-type="submit"
                    tag="input"
                    type="submit" value="Save">
          </b-button>
        </div>

      </b-field>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>
import User from "@/models/User"
import LessonPlan from "@/models/LessonPlan";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "LessonShareForm",
  components: {PerfectScrollbarWrapper, UsersFilter},
  data() {
    return {
      loading: false,
      filterMode: 0,
      selectedLearners: [],
      selectedTeachers: [],
      selectedGuardians: []
    }
  },
  methods: {
    selectTeacher(teacher) {
      if (!this.selectedTeachers.some(user => user.id === teacher.id)) {
        this.selectedTeachers.push(teacher)
      }
    }, selectGuardian(guardian) {
      if (!this.selectedGuardians.some(user => user.id === guardian.id)) {
        this.selectedGuardians.push(guardian)
      }
    },
    removeLearner(learner) {
      this.selectedLearners = this.selectedLearners.filter(user => user.id !== learner.id)
    },
    removeTeacher(teacher) {
      this.selectedTeachers = this.selectedTeachers.filter(user => user.id !== teacher.id)
    },
    removeGuardian(guardian) {
      this.selectedGuardians = this.selectedGuardians.filter(user => user.id !== guardian.id)
    },
    submit() {
      this.loading = true
      let ids = []
      this.selectedLearners.map(learner => ids.push(learner.id))
      this.selectedTeachers.map(teacher => ids.push(teacher.id))
      this.selectedGuardians.map(guardian => ids.push(guardian.id))

      LessonPlan.Share(this.lesson_plan_id, ids).then(() => {
        this.loading = false
        this.$buefy.snackbar.open('Lesson Plans Sent!')
        this.$emit('close')
      }).catch(err => {
        this.handleError(err)
        this.loading = false
      })
    }

  },

  computed: {
    type() {
      let val = 'Learners'
      if (this.filterMode === 0) {
        val = 'Learners'
      }
      if (
          this.filterMode === 1
      ) {
        val = 'Guardians'
      }
      if (this.filterMode === 2) {
        val = "Educators"
      }
      return val
    },
    learners() {
      return User.query().orderBy('first_name').get()
    }
  },
  mounted() {
    this.loading = true
    User.deleteAll()

    let promises = [User.FetchLearners(this.lesson_plan_id)]

    Promise.all(promises).then(() => {

      this.loading = false

    }).catch(err => {
      this.handleError(err)
      this.loading = false
    })
  },
  props: {
    campus_id: {
      type: Number, required: true
    },
    lesson_plan_id: {
      type: Number, required: true
    },

    inModal: {
      type: Boolean, default() {
        return true
      }
    },

  }
}
</script>

