<template>
  <div class="columns">
    <div class="column is-12">
      <div class="columns is-centered is-multiline">
        <div class="column is-12">
          <b-field grouped group-multiline class="unset-group-multiline-margin-bottom">
            <b-field expanded horizontal label="Year">
              <b-numberinput required v-model="year" min="2020" step="1">

              </b-numberinput>
            </b-field>
            <b-field expanded horizontal label="Term">
              <b-select @input="selectTerm" required expanded v-model="term_id">
                <optgroup v-for="semester in terms" :key="semester.id" :label="semester.name">
                  <option v-for="term in semester.children" :key="term.id" :value="term.id">{{ term.name }}</option>
                </optgroup>
              </b-select>
            </b-field>

            <b-field horizontal >
              <template #label><span>&nbsp;</span></template>
              <b-button @click="marking_absent=!marking_absent" :type="marking_absent?'is-primary':'is-link'">
                Mark{{ marking_absent ? 'ing' : '' }} Absent
              </b-button>
            </b-field>
            <!--            <b-field>-->
            <!--              <b-button type="is-primary" :icon-right="'content-save'">-->
            <!--                Save-->
            <!--              </b-button>-->
            <!--            </b-field>-->
          </b-field>
        </div>
        <hr>
      </div>

      <div class="column is-12">
        <div class="bar-container">
          <PerfectScrollbarWrapper>
            <div class="flippy">
              <b-table
                  v-if="term_id"
                  class="margin-top is-fullwidth"
                  :data="users"
                  :striped="true"
                  :key="assessmentKey"
                  :loading="loading"
                  :scrollable="false"
                  :hoverable="true"
                  :bordered="false"
              >
                <template #empty>
                  <div class="has-text-centered">No {{ $tc('Learner', 2) }}</div>
                </template>
                <b-table-column
                    v-slot="props"
                    label="ID"
                    field="id"
                    sortable
                    width="40"
                    numeric
                >{{ props.row.id }}
                </b-table-column>

                <b-table-column
                    v-slot="props"
                    sortable
                    field="last_name"
                    label="Student Surname"
                >{{ props.row.last_name }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    sortable
                    field="first_name"
                    label="Student Name"
                >{{ props.row.first_name }}
                </b-table-column>
                <b-table-column centered v-slot="props"
                                :visible="showAssessments"
                                :label="`${assessment.name} (${assessment.assessment_type})`"
                                v-for="(assessment,index) in orderedAssessments" :key="index + assessmentKey">
                  <div v-if="orderedAssessments.length>0" class="columns is-narrow is-centered is-vcentered">
                    <div class="column is-narrow pr-0">
                      <b-field>
                        <b-field v-if="!marking_absent && !assessment.absent_reason[props.row.id]">
                          <b-numberinput
                              :disabled="!canEditGrade"

                              @focus="focusedInput.assessment_id=assessment.id;focusedInput.user_id=props.row.id"
                              :custom-class="'has-text-right has-background-grey-light has-border-grey-light'" style="width: 100px"
                              @blur="processGrade($refs[`${assessment.id}-${props.row.id}`][0].newValue,assessment.id,props.row.id)"
                              @keydown.enter.native="processGrade($refs[`${assessment.id}-${props.row.id}`][0].newValue,assessment.id,props.row.id)"
                              :controls="false"
                              :ref="`${assessment.id}-${props.row.id}`"
                              :value="assessment.grades[props.row.id]"></b-numberinput>
                        </b-field>
                        <b-field v-else>
                          <b-select

                              @input="setAbsent($event,assessment.id,props.row.id)"
                              v-model="assessment.absent_reason[props.row.id]"
                              :ref="`${assessment.id}-${props.row.id}`"
                              :disabled="!canEditGrade"
                          >
                            <option :value="null">Not Absent</option>
                            <option v-for="reason in $store.state.assessments.absent_reasons" :value="reason.id"
                                    :key="reason.id">{{ reason.name }}
                            </option>
                          </b-select>
                        </b-field>

                      </b-field>


                    </div>
                    <div v-if="!marking_absent && !assessment.absent_reason[props.row.id]"
                        class="column is-narrow pl-1">
                      <span>/{{ assessment.total_marks }}</span>
                    </div>

                  </div>
                  <div v-else><p>
                    N/A
                  </p></div>
                </b-table-column>

              </b-table>
            </div>
          </PerfectScrollbarWrapper>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import {mapState} from "vuex";

import Term from "@/models/Term";
import Assessment from "@/models/Assessment";
import Grade from "@/models/Grade";
import Enrolment from "@/models/Enrolment";
import User from "@/models/User";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "Marksheet",
  components: { PerfectScrollbarWrapper },
  data() {
    return {
      assessmentKey: 0,
      showAssessments: false,
      marking_absent: false,
      loadingTerm: null,
      search: null,
      loadingGrades: [],
      disabledInputs: [],
      focusedInput: {assessment_id: null, user_id: null},
      expandedTerm: null,
      expandedParentTerm: null,
      year: this.selected_assessment ? new Date(this.selected_assessment.start).getFullYear() : new Date().getFullYear(),
      filter_by_date: false,
      loadingFilter: false,
      plansDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loading: false,
      loadingData: false,
      termsDataHold: null,
      term_id: null,
      focusedAssessment: this.selected_assessment,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null,
      orderedAssessments: [],
      gradeUpdateActions: [],
      gradeStoreActions: [],
      gradeDeleteActions: [],
      canEditGrade: false,
      canCreateGrade: false
    };
  },
  props: {
    selected_assessment: {
      type: Object,
      default() {
        return null
      }
    }, selected_subject: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Courses";
      },
    },
    createText: {
      type: String,
      default() {
        return "Course";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Subjects";
      },
    },

  },
  methods: {
    orderAssessments() {
      let orderedAssessments = []
      orderedAssessments = Assessment.query().orderBy('start').get()
      orderedAssessments.map(assessment => {
        assessment.grades = {}
        assessment.absent_reason = {}
        User.query().get().map(user => {
          assessment.grades[user.id] = this.gradeEntityValue(assessment.id, user.id)
          assessment.absent_reason[user.id] = this.gradeEntityAbsent(assessment.id, user.id)

        })
      })

      this.orderedAssessments = orderedAssessments
      this.showAssessments = this.orderedAssessments.length > 0
      this.assessmentKey++
    },
    startLoading(assessment_id, user_id) {
      this.$refs[`${assessment_id}-${user_id}`][0].loading = true
    },
    stopLoading(assessment_id, user_id) {
      this.$refs[`${assessment_id}-${user_id}`][0].loading = false

    },
    setAbsent(value, assessment_id, user_id) {
      let gradeEntity = this.gradeEntity(assessment_id, user_id)
      console.log(gradeEntity)
      if (value !== null) {
        if (gradeEntity === null) {
          this.startLoading(assessment_id, user_id)
          if (this.canCreateGrade) {
            return Grade.Store({
              user_id: user_id,
              assessment_id: assessment_id,
              is_absent: 1,
              absent_reason_id: value,
              total: 0
            }).then(() => {
              this.$buefy.snackbar.open({
                message: "Absentee Saved!",
                duration: 2000,
                queue: false,
                type: "is-primary"
              })
            }).catch(err => {
              this.handleError(err)
            }).finally(() => {
              this.stopLoading(assessment_id, user_id)
            })
          } else {
            this.stopLoading(assessment_id, user_id)
            this.$store.dispatch('toast/createToast')
          }
        } else {
          this.startLoading(assessment_id, user_id)
          if (this.canEditGrade) {

            return Grade.Update({
              id: gradeEntity.id,
              user_id: user_id,
              assessment_id: assessment_id,
              is_absent: 1,
              absent_reason_id: value,
              total: gradeEntity.total

            }, true).then(() => {
              this.$buefy.snackbar.open({
                message: "Absentee Saved!",
                duration: 2000,
                queue: false,
                type: "is-primary"
              })
            }).catch(err => {
              this.handleError(err)
            }).finally(() => {
              this.stopLoading(assessment_id, user_id)
            })
          } else {
            this.stopLoading(assessment_id, user_id)
            this.$store.dispatch('toast/createToast')
          }

        }

      } else {
        if (gradeEntity !== null) {
          this.startLoading(assessment_id, user_id)
          if (this.$store.getters['entities/user-permissions/find']('delete grades')) {
            return Grade.Update({
              id: this.gradeEntity(assessment_id, user_id).id,
              user_id: user_id,
              assessment_id: assessment_id,
              is_absent: 0,
              total: gradeEntity.total


            }, true).then(() => {
              this.$buefy.snackbar.open({
                message: "Absentee removed!",
                duration: 2000,
                queue: false,
                type: "is-primary"
              })
            }).catch(err => {
              this.handleError(err)
            }).finally(() => {
              this.stopLoading(assessment_id, user_id)
            })
          } else {
            this.$store.dispatch('toast/createToast').then(() => {
              this.stopLoading(assessment_id, user_id)
              this.$refs[`${assessment_id}-${user_id}`][0].newValue = gradeEntity.total

            })
          }
        }
      }

    }
    ,
    processGrade(value, assessment_id, user_id) {
      let gradeEntity = this.gradeEntity(assessment_id, user_id)
      if (value !== null) {
        let numValue = parseInt(value)
        if (typeof numValue === 'number') {
          if (gradeEntity === null) {
            this.startLoading(assessment_id, user_id)
            if (this.canCreateGrade) {
              Grade.Store({user_id: user_id, assessment_id: assessment_id, total: numValue}).then(() => {
                this.$buefy.snackbar.open({
                  message: "Grade Saved!",
                  duration: 2000,
                  queue: false,
                  type: "is-primary"
                })
              }).catch(err => {
                this.handleError(err)
              }).finally(() => {
                this.stopLoading(assessment_id, user_id)
              })
            } else {
              this.stopLoading(assessment_id, user_id)
              this.$store.dispatch('toast/createToast')
            }

          } else {
            if (gradeEntity.total !== numValue) {

              if (this.canEditGrade) {

                this.startLoading(assessment_id, user_id)

                Grade.Update({
                  id: gradeEntity.id,
                  user_id: user_id,
                  assessment_id: assessment_id,
                  total: numValue
                }, true).then(() => {
                  this.$buefy.snackbar.open({
                    message: "Grade Updated!",
                    duration: 2000,
                    queue: false,
                    type: "is-primary"
                  })
                }).catch(err => {
                  this.handleError(err)
                }).finally(() => {
                  this.stopLoading(assessment_id, user_id)
                })
              } else {
                this.stopLoading(assessment_id, user_id)
                this.$store.dispatch('toast/createToast')
              }
            }

          }
        } else {
          if (gradeEntity === null) {
            this.$refs[`${assessment_id}-${user_id}`][0].newValue = null
          } else {
            this.$refs[`${assessment_id}-${user_id}`][0].newValue = gradeEntity.total

          }
        }
      } else {
        if (gradeEntity !== null) {

          this.startLoading(assessment_id, user_id)
          if (this.$store.getters['entities/user-permissions/find']('delete grades')) {
            Grade.Delete(gradeEntity.id).then(() => {
              this.$buefy.snackbar.open({
                message: "Grade Deleted!",
                duration: 2000,
                queue: false,
                type: "is-primary"
              })
            }).catch(err => {
              this.handleError(err)
            }).finally(() => {
              this.stopLoading(assessment_id, user_id)
            })
          } else {
            this.$store.dispatch('toast/createToast').then(() => {
              this.stopLoading(assessment_id, user_id)
              this.$refs[`${assessment_id}-${user_id}`][0].newValue = gradeEntity.total

            })
          }
        }
      }

    },
    gradeEntity(assessment_id, user_id) {
      let grade = Grade.query().where('user_id', user_id).where('assessment_id', assessment_id).first()
      if (grade) {
        return grade
      }
      return null
    },
    gradeEntityValue(assessment_id, user_id) {
      let grade = Grade.query().where('user_id', user_id).where('assessment_id', assessment_id).first()
      if (grade) {
        return grade.total
      }
      return null
    }, gradeEntityAbsent(assessment_id, user_id) {
      let grade = Grade.query().where('user_id', user_id).where('assessment_id', assessment_id).first()
      if (grade) {
        if (grade.is_absent === 1) {
          return grade.absent_reason_id
        }
      }
      return null
    },
    processType(type) {
      let response = 'N/A'
      switch (type) {
        case 1:
          response = 'Task';
          break;
        case 2:
          response = 'Project';
          break;
        case 3:
          response = 'Formal';
          break;
        case 4:
          response = 'Exam';
          break
      }

      return response
    },
    expandParentTerm(term_id) {
      if (this.expandedParentTerm === term_id) {
        return this.expandedParentTerm = null
      }
      this.expandedParentTerm = term_id

    },
    selectTerm(term_id) {
      if (term_id === null) {
        return this.term_id = null
      }
      this.loading = true
      this.term_id = term_id
      Assessment.deleteAll()
      Enrolment.deleteAll()
      Assessment.FetchAll({page: 1, limit: 999}, {
        term_id: term_id,
        subject_id: this.selected_subject.id, ...(this.$store.state.campus.selected_class_group_id
            ? {
              class_group_id: this.$store.state.campus.selected_class_group_id,
            }
            : {})
      }, [], 'create').then(() => {
        let assessments_ids = []
        Assessment.query().where('term_id', this.term_id).orderBy('assessment_date').get().map(assessment => assessments_ids.push(assessment.id))
        User.deleteAll()
        let promises = [Enrolment.FetchEnrolmentsBySubjectId(this.selected_subject.id, ['user'], {
          page: 1,
          limit: 999
        }, {year: this.year, class_group_id: this.$store.state.campus.selected_class_group_id}, 'create')]
        if (assessments_ids.length > 0) {
          promises.push(Grade.FetchAll({page: 1, limit: 9999}, {
            assessment_id: assessments_ids,
            term_id: term_id
          }), [], 'create')
        }
        Promise.all(promises).then(() => {
          this.loading = false
          this.orderAssessments()
        })
      })
    },

    getTerms() {

      this.$store.dispatch('loader/show')
      this.termsDataHold = this.terms;
      Term.deleteAll()
      return Term.FetchAll(
          {
            page: 1,
            limit: 99,
          },
          {year: this.year, is_parent: 1, campus_id: this.$store.state.campus.selected_campus_id},
          ["children"]
      ).then(
          () => {
            this.termsDataHold = null;
          }
      ).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      });
    },


  },
  watch: {
    // term_id(new_value) {
    //   this.selectTerm(new_value)
    // },
    selected_class_group_id() {
      Term.deleteAll()
      Assessment.deleteAll()
      this.getTerms().then(() => {
        if (this.terms.length > 0) {
          this.selectTerm(this.selected_assessment ? this.selected_assessment.term_id : this.childTerms[0].id)
        } else {
          this.selectTerm(null)
        }
      })

    },
    year(newValue) {
      if (newValue !== null) {
        if (newValue > 2019) {
          this.getTerms().then(() => {
            if (this.terms.length > 0) {
              this.selectTerm(this.selected_assessment ? this.selected_assessment.term_id : this.childTerms[0].id)
            } else {
              this.selectTerm(null)
            }
          })
        }
      }
    }
  },
  computed: {
    ...mapState("campus", ["selected_class_group_id"]),
    // orderedAssessments() {
    //   let orderedAssessments = []
    //   orderedAssessments = Assessment.query().where(assessment => {
    //     return this.$store.state.campus.selected_class_group_id ? assessment.class_group_id === this.$store.state.campus.selected_class_group_id : true
    //   }).orderBy('start').get()
    //   orderedAssessments.map(assessment => {
    //     assessment.grades = {}
    //     assessment.absent_reason = {}
    //     User.query().get().map(user => {
    //       assessment.grades[user.id] = this.gradeEntityValue(assessment.id, user.id)
    //       assessment.absent_reason[user.id] = this.gradeEntityAbsent(assessment.id, user.id)
    //     })
    //   })
    //   return orderedAssessments
    // },
    childTerms() {
      return Term.query().where(term => term.parent_id !== null).orderBy('start').get()
    },
    // assessments() {
    //   if (this.assessmentsDataHold === null) {
    //     return Assessment.query().where('term_id', this.term_id).orderBy('assessment_date').get();
    //   } else {
    //     return this.assessmentsDataHold;
    //   }
    // },
    users() {
      return User.query().orderBy('last_name').get()
    },
    terms() {
      if (this.termsDataHold === null) {
        return Term.query().where('parent_id', null).where(term => {
          return new Date(term.start).getFullYear() === this.year
        }).with('children').orderBy('start').get();
      } else {
        return this.termsDataHold;
      }
    },


  },
  mounted() {
    this.$store.dispatch("loader/show");
    this.canCreateGrade = !!this.$store.getters['entities/user-permissions/find']('create grades')
    this.canEditGrade = !!this.$store.getters['entities/user-permissions/find']('edit grades')
    // this.psInit()
    Term.deleteAll()
    Assessment.deleteAll()
    this.getTerms().then(() => {
      if (this.terms.length > 0) {
        this.selectTerm(this.selected_assessment ? this.selected_assessment.term_id : this.childTerms[0].id)
      } else {
        this.selectTerm(null)
      }
    })

  },
};
</script>

<style lang="scss">


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar-container {
  max-width: 99%;
  overflow-x: hidden;
}

.flippy {
  max-width: 100%;
}

.bar-container,
.flippy {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}
</style>
