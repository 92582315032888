<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field grouped>
          <b-field horizontal label="Year">
            <b-numberinput required v-model="year" min="2020" step="1">

            </b-numberinput>
          </b-field>
        </b-field>
      </div>
    </div>
    <hr>
    <div v-for="(term,index) in terms" :key="term.id">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <p class="is-size-3">{{ term.name }}</p>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-field>
              <b-button @click="expandParentTerm(term.id)">
                {{ expandedParentTerm !== term.id ? 'Expand' : 'Collapse' }}
              </b-button>
            </b-field>
          </div>
        </div>
      </div>
      <div class="ml-3" v-show="expandedParentTerm === term.id" v-for="(child,index) in term.children" :key="child.id">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p class="is-size-4">{{ child.name }}</p>
            </div>
          </div>
          <div class="level-right">
            <div v-if="!!canCreate" class="level-item">
              <b-field>
                <b-button @click="startCreateAssessment(child.id)" :icon-left="$tc('icons.create')" type="is-primary">
                  New Assessment
                </b-button>
              </b-field>
            </div>
            <div class="level-item">
              <b-field>
                <b-button @click="expandTerm(child.id)">
                  {{ expandedTerm !== child.id ? 'Expand' : 'Collapse' }}
                </b-button>
              </b-field>
            </div>
          </div>
        </div>
        <b-table
            v-if="expandedTerm===child.id"
            class="margin-top"
            :data="assessments"
            :striped="true"
            :loading="loadingTerm===child.id"
            :hoverable="true"
            :bordered="false"
        >
          <template #empty>
            <div class="has-text-centered">No Assessments</div>
          </template>
          <b-table-column
              v-slot="props"
              label="ID"
              field="id"
              sortable
              width="40"
              numeric
          >{{ props.row.id }}
          </b-table-column>

          <b-table-column
              v-slot="props"
              sortable
              field="name"
              label="Name"
          >{{ props.row.name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              sortable
              field="total_marks"
              label="Total Marks"
          >{{ props.row.total_marks }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              sortable
              field="assessment_date"
              label="Assessment Date"
          >{{ props.row.assessment_date }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              sortable
              field="type_id"
              label="Assessment Type"
          >{{ processType(props.row.type_id) }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              sortable
              field="class_group_id"
              label="Class"
          >{{ props.row.class_group_id ? props.row.class_group.name : 'Unassigned' }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              sortable
              field="is_global"
              label="Global Assessment"
          >{{ props.row.is_global ? 'Yes' : 'No' }}
          </b-table-column>

          <b-table-column
              v-slot="props"
              label="Actions"
              custom-key="actions"
              centered

          >
            <b-dropdown position="is-bottom-left"
                        append-to-body aria-role="list">
              <template #trigger="{ active }">
                <b-button
                    label="Actions"
                    type="is-primary"
                    :icon-right="active ? 'menu-up' : 'menu-down'"/>
              </template>

              <b-dropdown-item @click="openAssessment(props.row)" aria-role="listitem">
                View{{ canEdit ? '/Edit' : '' }}
              </b-dropdown-item>
              <b-dropdown-item v-if="props.row.type_id ===4" @click="openAssessmentThresholds(props.row)"
                               aria-role="listitem">
                Manage Grade Thresholds
              </b-dropdown-item>
              <b-dropdown-item v-if="canDelete" @click="startDeleteAssessment(props.row)" aria-role="listitem">
                Delete
              </b-dropdown-item>

            </b-dropdown>
          </b-table-column>


        </b-table>
        <hr v-if="index!==term.children.length-1">
      </div>
      <hr v-if="index!==terms.length-1">

    </div>
  </div>

</template>

<script>

import {mapState} from "vuex";

import Term from "@/models/Term";
import Assessment from "@/models/Assessment";
import AssessmentForm from "@/components/assessments/AssessmentForm";
import AssessmentThresholdsForm from "@/components/assessments/AssessmentThresholdsForm";

export default {
  name: "Assessments.index",
  components: {},
  data() {
    return {
      loadingTerm: null,
      search: null,
      expandedTerm: null,
      expandedParentTerm: null,
      year: new Date().getFullYear(),
      filter_by_date: false,
      loadingFilter: false,
      plansDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      termsDataHold: null,
      assessmentsDataHold: null,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null
    };
  },
  props: {
    selected_subject: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    }, canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Courses";
      },
    },
    createText: {
      type: String,
      default() {
        return "Course";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Subjects";
      },
    },

  },
  methods: {
    startCreateAssessment(term_id) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          subject_id: this.selected_subject.id,
          edit: false,
          canCreate: this.canCreate,
          canEdit: this.canEdit,
          term_id: term_id,
          inModal: true,
          manageTerms: true,
        },
        component: AssessmentForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'escape', 'x']
      });
    },
    startDeleteAssessment(assessment) {
      this.$buefy.dialog.confirm({
        title: "Deleting assessment",
        message:
            "Are you sure you want to <b>delete</b> this assessment? This action cannot be undone.",
        confirmText: "Delete Assessment",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteAssessment(assessment),
      });
    },
    deleteAssessment(assessment) {
      this.$store.dispatch("loader/show");
      Assessment.Delete(assessment.id).then(
          () => {
            this.$buefy.snackbar.open('Assessment deleted!')
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      ).finally(() => {
        this.$store.dispatch("loader/hide");

      })

    },
    processType(type) {
      let response = 'N/A'
      switch (type) {
        case 1:
          response = 'Task';
          break;
        case 2:
          response = 'Project';
          break;
        case 3:
          response = 'Formal';
          break;
        case 4:
          response = 'Exam';
          break
      }

      return response
    },
    expandParentTerm(term_id) {
      if (this.expandedParentTerm === term_id) {
        return this.expandedParentTerm = null
      }
      this.expandedParentTerm = term_id

    },
    expandTerm(term_id) {
      if (this.expandedTerm === term_id) {
        return this.expandedTerm = null
      }
      this.loadingTerm = term_id
      this.assessmentsDataHold = this.assessments;
      Assessment.deleteAll()
      Assessment.FetchAll({page: 1, limit: 999}, {
        term_id: term_id, subject_id: this.selected_subject.id, ...(this.$store.state.campus.selected_class_group_id
            ? {
              class_group_id: this.$store.state.campus.selected_class_group_id,
            }
            : {})
      }).then(() => {
        this.expandedTerm = term_id
        this.assessmentsDataHold = null
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingTerm = null
      })
    },
    getTerms() {
      this.$store.dispatch('loader/show')
      this.termsDataHold = this.terms;
      Term.deleteAll()
      return Term.FetchAll(
          {
            page: 1,
            limit: 99,
          },
          {year: this.year, is_parent: 1, campus_id: this.$store.state.campus.selected_campus_id},
          ["children"]
      ).then(
          () => {

            this.termsDataHold = null;
          }
      ).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.$store.dispatch('loader/hide')
      });
    },


    openAssessment(assessment) {
      this.setQuery({assessment_id: assessment.id})
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: assessment.campus_id,
          subject_id: assessment.subject_id,
          edit: true,
          canCreate: this.canCreate,
          canEdit: this.canEdit,
          inModal: true,
          manageTerms: true,
          assessment: assessment
        },
        onCancel: () => {
          this.removeQuery(['assessment_id'])
        },
        events: {
          'close': () => {
            this.removeQuery(['assessment_id'])
          }
        },
        component: AssessmentForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['escape', 'outside', 'x']
      });
    }, openAssessmentThresholds(assessment) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          canCreate: this.canEdit,
          canEdit: this.canEdit,
          canDelete: this.canEdit,
          inModal: true,
          assessment_id: assessment.id
        },
        component: AssessmentThresholdsForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['escape', 'x']
      });
    },
    clear() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },


  },
  watch: {
    selected_class_group_id() {
      Term.deleteAll()
      Assessment.deleteAll()
      this.expandedTerm = null
      this.getTerms().then(() => {
        this.expandParentTerm(this.terms.length > 0 ? this.terms[0].id : null)
      })

    },
    year(newValue) {
      if (newValue !== null) {
        if (newValue > 2019) {
          this.getTerms().then(() => {
            this.expandParentTerm(this.terms.length > 0 ? this.terms[0].id : null)
          })
        }
      }
    }
  },
  computed: {
    ...mapState("campus", ["selected_class_group_id"]),

    childTerms() {
      return Term.query().where('parent_id', this.expandedParentTerm).orderBy('start').get()
    },
    assessments() {
      if (this.assessmentsDataHold === null) {
        return Assessment.query().where('term_id', this.expandedTerm).with('class_group').orderBy('assessment_date').get();
      } else {
        return this.assessmentsDataHold;
      }
    },
    terms() {
      if (this.termsDataHold === null) {
        return Term.query().where('parent_id', null).where(term => {
          return new Date(term.start).getFullYear() === this.year
        }).with('children').orderBy('start').get();
      } else {
        return this.termsDataHold;
      }
    },

    ...mapState('campus', ["selected_campus_id", "all_campuses"]),


  },
  mounted() {
    this.$store.dispatch("loader/show");

    Term.deleteAll()
    Assessment.deleteAll()
    if (this.$route.query.assessment_id) {
      Assessment.FetchById(parseInt(this.$route.query.assessment_id)).then(() => {
        this.openAssessment(Assessment.query().whereId(parseInt(this.$route.query.assessment_id)).withAll().first())
      }).catch(err => {
        this.handleError(err)
      })
    }
    this.getTerms().then(() => {
      this.expandParentTerm(this.terms.length > 0 ? this.terms[0].id : null)
    })

  },
};
</script>
