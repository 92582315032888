<template>
  <div>
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ $tc('Learner', 1) }} Reflections</p>
      </header>
      <PerfectScrollbarWrapper>
        <section :class="{'modal-card-body':inModal}">
          <div v-for="reflection in reflections" :key="reflection.id">
            <div @click.prevent="toggleActive(reflection.id)"
                class="level has-pointer">
              <div class="level-left">
                <div class="level-item"><p class="image is-48x48">
                  <img :src="reflection.user.avatar"
                      :alt="reflection.user.first_name + ' ' +reflection.user.last_name + ' ' + 'avatar'"
                      class="is-rounded"
                  >
                </p>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <p>{{ reflection.user.first_name }} {{ reflection.user.last_name }}</p>
                </div>
                <div class="level-item">
                  <a
                      class="card-header-icon has-text-link px-0 py-0"
                  >
                    <b-icon :icon="activeReflection===reflection.id?'menu-up':'menu-down'" size="is-medium"/>
                  </a>
                </div>
              </div>
            </div>
            <div v-show="activeReflection===reflection.id"><p>How comfortable do you feel about today’s lesson?</p>
              <p class="has-text-weight-semibold pb-3">I had no idea what was going on.</p>
              <div class="has-text-centered">
                <b-field style="max-width: 100%">
                  <b-radio :disabled="true" required :value="reflection.how_comfortable"
                          name="name"
                          :native-value="1">
                    1
                  </b-radio>
                </b-field>
                <b-field style="max-width: 100%">
                  <b-radio :disabled="true" required :value="reflection.how_comfortable"
                          name="name"
                          :native-value="2">
                    2

                  </b-radio>
                </b-field>
                <b-field style="max-width: 100%">
                  <b-radio :disabled="true" required :value="reflection.how_comfortable"
                          name="name"
                          :native-value="3">
                    3
                  </b-radio>
                </b-field>
                <b-field style="max-width: 100%">
                  <b-radio :disabled="true" required :value="reflection.how_comfortable"
                          name="name"
                          :native-value="4"
                  >
                    4
                  </b-radio>
                </b-field>
                <b-field style="max-width: 100%">
                  <b-radio :disabled="true" required :value="reflection.how_comfortable"
                          name="name"
                          :native-value="5"
                  >
                    5
                  </b-radio>
                </b-field>
              </div>
              <p class="has-text-weight-semibold pt-3">I understood everything and could teach others.</p>
              <b-field label="The easiest concept today was...">
                <b-input :disabled="true" :value="reflection.easiest_concept" required></b-input>
              </b-field>
              <b-field label="The most difficult concept today was...">
                <b-input :disabled="true" :value="reflection.hardest_concept" required></b-input>
              </b-field>
              <b-field position="is-centered" class="has-text-centered pt-4">
                <b-button :disabled="true" :loading="loading" native-type="submit" type="is-primary">Submit
                </b-button>
              </b-field>
            </div>

          </div>

        </section>
      </PerfectScrollbarWrapper>
      <footer v-if="inModal" class="modal-card-foot">
        <button :loading="loading" class="button" type="is-primary"
                @click="$emit('close')">Close
        </button>
      </footer>
      <b-field grouped class="mt-4" v-else>
        <div class="control">
          <button :loading="loading" class="button" type="is-primary"
                  @click="$emit('close')">Close
          </button>
        </div>

      </b-field>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </div>
</template>

<script>

import LearnerReflection from "@/models/LearnerReflection";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  components: { PerfectScrollbarWrapper },
  name: "LearnerShareForm",
  data() {
    return {
      activeReflection: null,
      loading: false,
      selectedLearners: [],
    }
  },
  methods: {
    toggleActive(id) {
      id === this.activeReflection ? this.activeReflection = null : this.activeReflection = id
    }
  },

  computed: {
    reflections() {
      return LearnerReflection.query().orderBy('user.first_name').with("user").get()
    }
  },
  mounted() {
    this.loading = true
    LearnerReflection.deleteAll()

    let promises = [LearnerReflection.FetchAll({page: 1, limit: 999}, {lesson_plan_id: this.lesson_plan_id}, ['user'])]

    Promise.all(promises).then(() => {

      this.loading = false

    }).catch(err => {
      this.handleError(err)
      this.loading = false
    })
  },
  props: {
    lesson_plan_id: {
      type: Number, required: true
    },

    inModal: {
      type: Boolean, default() {
        return true
      }
    },

  }
}
</script>

