<template>
  <div>
    <div class="columns">
      <div class="column">
        <b-field grouped>
          <b-input v-model="searchHold" v-debounce:300ms="getFilteredLessonPlans" expanded placeholder="Search"
          ></b-input>
        </b-field>
      </div>
    </div>
    <hr>
    <b-table
        :bordered="false"
        :data="lessonPlans"
        :hoverable="true"
        :loading="loadingData"
        :striped="true"
        class="margin-top is-clickable"
        @click="openLessonPlan"
    >
      <template #empty>
        <div class="has-text-centered">No Lesson Plans</div>
      </template>
      <!--          :backend-sorting="true"-->
      <!--          :default-sort="['first_name', 'asc']"-->
      <!--          @sort="onSort"-->
      <b-table-column
          v-slot="props"
          field="id"
          label="ID"
          numeric
          sortable
          width="40"
      >{{ props.row.id }}
      </b-table-column>
      <!--      <b-table-column-->
      <!--          v-slot="props"-->
      <!--          label="Code"-->
      <!--          field="code"-->
      <!--          sortable-->
      <!--          width="40"-->
      <!--          numeric-->
      <!--      >{{ props.row.code }}-->
      <!--      </b-table-column>-->

      <!--          <b-table-column-->
      <!--              v-slot="props"-->
      <!--              sortable-->
      <!--              field="course_scope.learning_stream.name"-->
      <!--              label="Learning Stream"-->
      <!--          >{{ props.row.course_scope.learning_stream.name }}-->
      <!--          </b-table-column>-->
      <b-table-column
          v-slot="props"
          field="topic.reference"
          label="Topic"
          sortable
      >{{ props.row.topic.reference }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="name"
          label="Name"
          sortable
      >{{ props.row.name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="class_group_id"
          label="Class"
          sortable
      >{{
          (props.row.hasOwnProperty('class_group') && props.row.class_group !== null) ? props.row.class_group.name : 'Unassigned'
        }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="is_global"
          label="Shared with other classes"
          sortable
      >{{ props.row.is_global === 1 ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          cell-class="is-flex is-justify-content-end"
          label="Actions"
      >
        <div style="width: fit-content" v-on:click.stop>
          <b-dropdown append-to-body
                      aria-role="list" position="is-bottom-left">
            <template #trigger>
              <b-icon
                  :icon="$tc('icons.more')" class="mx-6"/>
            </template>

            <b-dropdown-item aria-role="listitem" @click="startTeacherReflection(props.row)">
              View Educator Reflections
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="startHODReflection(props.row)">
              View HOD Reflections
            </b-dropdown-item>
            <!--suppress JSPotentiallyInvalidConstructorUsage -->
            <b-dropdown-item aria-role="listitem" @click="ViewLearnerReflections(props.row)">
              View [{{ props.row.learner_reflections_count }}] {{ $tc('Learner') }} Reflections
            </b-dropdown-item>
            <b-dropdown-item v-if="canDelete" aria-role="listitem" @click="startDelete(props.row)">
              Delete
            </b-dropdown-item>

          </b-dropdown>
        </div>

      </b-table-column>
    </b-table>
    <b-pagination
        :current="page"
        :per-page="limit"
        :range-after="2"
        :range-before="2"
        :total="meta.total"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        class="mt-4"
        v-on:change="setPage"
    ></b-pagination>
  </div>

</template>

<script>
import Campus from "@/models/Campus";
import CourseScopeForm from "@/components/courses/CourseScopeForm";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
// import DatePicker from "@/components/dates/DatePicker";
import {mapState} from "vuex";
// import Subject from "@/models/Subject";
import LessonPlan from "@/models/LessonPlan";
import LessonPlanForm from "@/components/lessonPlans/LessonPlanForm";
import TeacherReflection from "@/models/TeacherReflection";
import TeacherReflectionForm from "@/components/reflections/TeacherReflectionForm";
import LearnerReflectionList from "@/components/reflections/LearnerReflectionList";
// import CourseScope from "@/models/CourseScope";

export default {
  name: "LessonPlans.index",
  components: {
    // DatePicker
  },
  data() {
    return {
      search: null,
      filter_by_date: false,
      loadingFilter: false,
      plansDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
      phaseSearchId: null,
      stageSearchId: null
    };
  },
  props: {
    selected_subject: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default() {
        return "Courses";
      },
    },
    createText: {
      type: String,
      default() {
        return "Course";
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Subjects";
      },
    },

  },
  methods: {
    startDelete(lessonPlan) {
      this.$buefy.dialog.confirm({
        title: `Deleting Lesson Plan`,
        confirmText: `Delete Lesson Plan`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this Lesson Plan?`,
        onConfirm: () =>
            LessonPlan.Delete(lessonPlan.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Lesson Plan deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    ViewLearnerReflections(plan) {

      if (plan.learner_reflections_count > 0) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            lesson_plan_id: plan.id,
            inModal: true,
          },
          component: LearnerReflectionList,
          hasModalCard: true,
          fullScreen: false,
          trapFocus: true,
        });
      }
    },
    startTeacherReflection(plan) {

      if (TeacherReflection.query().where('lesson_plan_id', plan.id).where('type_id', 1).exists()) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            type_id: TeacherReflection.query().where('lesson_plan_id', plan.id).where('type_id', 1).first().type_id,
            lesson_plan_id: plan.id,
            teacherReflection: TeacherReflection.query().where('lesson_plan_id', plan.id).where('type_id', 1).first(),
            inModal: true,
            edit: true
          },
          component: TeacherReflectionForm,
          hasModalCard: true,
          fullScreen: false,
          trapFocus: true,
          canCancel: ['outside', 'x']
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          props: {
            type_id: 1,
            lesson_plan_id: plan.id,
            inModal: true,
            edit: false
          },
          component: TeacherReflectionForm,
          hasModalCard: true,
          fullScreen: false,
          trapFocus: true,
          canCancel: ['outside', 'x']
        });
      }
    },
    startHODReflection(plan) {
      if (TeacherReflection.query().where('lesson_plan_id', plan.id).where('type_id', 2).exists()) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            type_id: TeacherReflection.query().where('lesson_plan_id', plan.id).where('type_id', 2).first().type_id,
            lesson_plan_id: plan.id,
            teacherReflection: TeacherReflection.query().where('lesson_plan_id', plan.id).where('type_id', 2).first(),
            inModal: true,
            edit: true
          },
          component: TeacherReflectionForm,
          hasModalCard: true,
          fullScreen: false,
          trapFocus: true,
          canCancel: ['outside', 'x']
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          props: {
            type_id: 2,
            lesson_plan_id: plan.id,
            inModal: true,
            edit: false
          },
          component: TeacherReflectionForm,
          hasModalCard: true,
          fullScreen: false,
          trapFocus: true,
          canCancel: ['outside', 'x']
        });
      }
    },
    openLessonPlan(plan) {
      this.$store.dispatch('loader/show').then(() => {
        this.$buefy.modal.open({
          parent: this,
          props: {
            defaultDate: this.$store.state.attendance.attendance_date_truth,
            campus_id: this.$store.state.campus.selected_campus_id,
            lessonPlan: plan,
            edit: true,
            course_scope_id: this.selected_subject.course_scope_id,
            inModal: true,

          },
          component: LessonPlanForm,
          fullScreen: false,
          trapFocus: true,
          canCancel: ['outside', 'x']
        })
      })


    },
    clear() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
    },

    viewSubjectPlans(subject) {
      this.$emit('selected', subject)
    },
    viewCourse(user) {
      this.$cookies.set(
          `course_filters`,
          {
            page: this.page,
            phaseSearchId: this.phaseSearchId,
            stageSearchId: this.stageSearchId
          },
          "1d"
      );
      if (this.use_modal) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
          },
          component: CourseScopeForm,
          fullScreen: true,
          trapFocus: true,
        });
      } else {
        this.$router.push(`/courses/edit/${user.id}`);
      }
    },
    getFilteredLessonPlans(text) {
      this.search = text;
    },
    setPage(pageNumber) {
      this.loadingData = true;
      this.plansDataHold = this.courses;
      LessonPlan.deleteAll();
      this.page = pageNumber;
      LessonPlan.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters, ['course_scope_topic', 'objectives', 'teacher_reflection'], ['teacher_reflection', 'learner_reflections']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.$store.dispatch("loader/hide");

            this.meta = meta;
            this.loadingData = false;
            this.plansDataHold = null;


          }
      ).catch(err => {
        this.handleError(err)
        this.loadingData = false;
        this.$store.dispatch("loader/hide");

      });
    },
  },
  watch: {

    all_campuses(value) {
      if (value === true) {
        this.phaseSearchId = null
        this.stageSearchId = null
      }
    },
    selected_campus_id() {
      this.phaseSearchId = null
      this.stageSearchId = null
    },
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    phase_filter: {
      set(val) {
        this.phaseSearchId = val
        this.stageSearchId = null
      }, get() {
        return this.phaseSearchId
      }
    },
    ...mapState('campus', ["selected_campus_id", "all_campuses"]),
    campuses() {
      return Campus.query().get();
    },
    phases() {
      return Phase.query().where((phase) => {
        return this.$store.state.campus.all_campuses ? true : phase.campus_id === this.$store.state.campus.selected_campus_id
      }).get()
    },
    stages() {
      return Stage.query().where('phase_id', this.phaseSearchId).get()
    },
    filters() {
      return {
        ...{
          course_scope_id: this.selected_subject.course_scope_id
        },
        ...{
          subject_id: this.selected_subject.id
        },
        // ...(this.filter_by_date
        //     ? {
        //       lesson_date: this.$store.getters['attendance/attendance_date_filter'],
        //     }
        //     : {}),
        ...(this.$store.state.campus.selected_class_group_id
            ? {
              class_group_id: this.$store.state.campus.selected_class_group_id,
            }
            : {}), ...(this.search
            ? {
              search: this.search,
            }
            : {}),
      };
    },
    lessonPlans() {
      if (this.plansDataHold === null) {
        return LessonPlan.query().with('course_scope').with('topic').with('objectives').with('class_group').with('teacher_reflection').orderBy('lesson_date').get();
      } else {
        return this.plansDataHold;
      }
    },

  },
  mounted() {
    this.$store.dispatch("loader/show");


    this.setPage(this.page, true);


  },
};
</script>
