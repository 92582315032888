<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'View' }} Reflection</p>
      </header>
      <PerfectScrollbarWrapper>
        <section :class="{'modal-card-body':inModal}">
          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">
    Were the <b>teaching methods</b> used in this lesson effective?
    </span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.were_teaching_methods_effective">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">
    Were the <b>learning activities</b> used effective?</span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.were_learning_activities_effective">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">
    Were <b>formative assessment</b> strategies used throughout?
    </span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.were_formative_assessment_strategies_used_throughout">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">
    Were the <b>instructional materials</b> used effectively?</span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.were_instructional_materials_used_effectively">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">

    Were the students provided with an opportunity to make links with <b>prior knowledge</b>?
    </span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.were_prior_knowledge_links_available">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">

    Do you think that the <b>lesson outcomes</b> were achieved?</span></template>
            <b-select :disabled="edit" required expanded v-model="teacherReflectionObject.was_lesson_outcome_achieved">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded group-multiline grouped>
            <template #label>
              <span
                  class="has-text-weight-normal">What <b>collaboration strategies</b> were used during this lesson?</span>
            </template>
            <p class="control">
              <b-checkbox :disabled="edit" v-model="collaboration_groupwork">
                Groupwork
              </b-checkbox>
            </p>
            <p class="control">
              <b-checkbox :disabled="edit" v-model="collaboration_paired_work">
                Paired work
              </b-checkbox>
            </p>
            <p class="control">
              <b-checkbox :disabled="edit" v-model="collaboration_think_pair_share">
                Think-pair-share
              </b-checkbox>
            </p>
            <p class="control">
              <b-checkbox :disabled="edit" v-model="collaboration_group_discussions">
                Group discussions
              </b-checkbox>
            </p>
            <p>
              <b-checkbox :disabled="edit" v-model="collaboration_other">
                Other
              </b-checkbox>
            </p>
            <b-field v-if="collaboration_other">
              <template #label>
              <span
                  class="has-text-weight-normal">Other strategies:</span>
              </template>
              <b-input :disabled="edit" v-model="otherText" type="textarea"></b-input>
            </b-field>
          </b-field>

          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">

    Would you say this lesson encouraged <b>active learning</b>?</span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.did_encourage_active_learning">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">

    Were there opportunities provided for <b>differentiation</b>?</span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.were_differentiation_opportunities_available">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded>
            <template #label>
    <span class="has-text-weight-normal">


    Were the students provided with an opportunity for <b>reflection</b>?
    </span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.were_students_allowed_to_reflect">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field expanded>
            <template #label>
              <span
                  class="has-text-weight-normal"><b class="has-text-underlined">General comment:</b></span>
            </template>
            <b-input :disabled="edit" expanded v-model="teacherReflectionObject.comment" type="textarea"></b-input>

          </b-field>
          <b-field v-if="teacherReflectionObject.type_id===1">
            <span class="is-italic">(If you were to teach this lesson to the same group of students, what would you do differently? Why? What would you do the same? Why? What surprised you in the lesson? Any general comments. Identify own strengths and areas for future development.)</span>
          </b-field>
          <b-field v-if="teacherReflectionObject.type_id===1" expanded>
            <template #label>
              <span
                  class="has-text-weight-normal">Based on what happened in the lesson, what are the <b>next steps</b>? What will you be teaching next?</span>
            </template>
            <b-input :disabled="edit" expanded v-model="teacherReflectionObject.next_steps" type="textarea"></b-input>

          </b-field>
          <!--          <b-field v-if="teacherReflectionObject.type_id===1" expanded-->
          <!--                  label="Identify an individual or group of students who did especially well in this lesson.">-->
          <!--            <b-taginput-->
          <!--                @input="addGoodComment"-->
          <!--                :disabled="edit"-->
          <!--                @keydown.enter.native.prevent-->
          <!--                v-model="good_students"-->
          <!--                :data="good_students_computed"-->
          <!--                autocomplete-->
          <!--                :allow-new="false"-->
          <!--                :open-on-focus="true"-->
          <!--                ref="goodTagInput"-->
          <!--                icon="label"-->
          <!--                placeholder="Add an exemplary student"-->
          <!--                @typing="getFilteredGoodStudents">-->
          <!--              <template slot-scope="props">-->
          <!--                {{ props.option.first_name }} {{ props.option.last_name }}-->
          <!--              </template>-->
          <!--              <template #empty>-->
          <!--                There are no {{ $tc('learner', 2) }}-->
          <!--              </template>-->
          <!--              <template #selected="props">-->
          <!--                <b-tag-->
          <!--                    v-for="(tag, index) in props.tags"-->
          <!--                    :key="index"-->
          <!--                    :closable="!edit"-->
          <!--                    @close="$refs.goodTagInput.removeTag(index, $event)">-->
          <!--                  {{ tag.first_name }} {{ tag.last_name }}-->
          <!--                </b-tag>-->
          <!--              </template>-->
          <!--            </b-taginput>-->
          <!--          </b-field>-->
          <!--          <div v-if="teacherReflectionObject.type_id===1">-->
          <!--            <b-field v-for="(comment,index) in good_student_comments" :key="index"-->
          <!--                    :label="`Comment for ${comment.user.first_name} ${comment.user.last_name}`">-->
          <!--              <b-input :disabled="edit" v-model="comment.comment" type="textarea"></b-input>-->
          <!--            </b-field>-->
          <!--          </div>-->
          <!--          <b-field v-if="teacherReflectionObject.type_id===1" expanded-->
          <!--                  label="Identify an individual or group of students who had difficulty in today’s lesson.">-->
          <!--            <b-taginput-->
          <!--                @input="addBadComment"-->
          <!--                :disabled="edit"-->
          <!--                @keydown.enter.native.prevent-->
          <!--                v-model="bad_students"-->
          <!--                :data="bad_students_computed"-->
          <!--                autocomplete-->
          <!--                :allow-new="false"-->
          <!--                :open-on-focus="true"-->
          <!--                ref="badTagInput"-->
          <!--                icon="label"-->
          <!--                placeholder="Add an exemplary student"-->
          <!--                @typing="getFilteredBadStudents">-->
          <!--              <template slot-scope="props">-->
          <!--                {{ props.option.first_name }} {{ props.option.last_name }}-->
          <!--              </template>-->
          <!--              <template #empty>-->
          <!--                There are no {{ $tc('learner', 2) }}-->
          <!--              </template>-->
          <!--              <template #selected="props">-->
          <!--                <b-tag-->
          <!--                    v-for="(tag, index) in props.tags"-->
          <!--                    :key="index + 'good'"-->
          <!--                    :closable="!edit"-->
          <!--                    @close="$refs.badTagInput.removeTag(index, $event)">-->
          <!--                  {{ tag.first_name }} {{ tag.last_name }}-->
          <!--                </b-tag>-->
          <!--              </template>-->
          <!--            </b-taginput>-->
          <!--          </b-field>-->
          <!--          <div v-if="teacherReflectionObject.type_id===1">-->
          <!--            <b-field :disabled="edit" v-for="(comment,index) in bad_student_comments" :key="index + 'bad'"-->
          <!--                    :label="`Comment for ${comment.user.first_name} ${comment.user.last_name}`">-->
          <!--              <b-input v-model="comment.comment" type="textarea"></b-input>-->
          <!--            </b-field>-->
          <!--          </div>-->
          <b-field v-if="teacherReflectionObject.type_id===2" expanded>
            <template #label>
    <span class="has-text-weight-normal">
    Are the lecturer’s <b>planning documents</b> and lesson preparation sufficient?</span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.were_planning_documents_and_prep_sufficient">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field v-if="teacherReflectionObject.type_id===2" expanded>
            <template #label>
    <span class="has-text-weight-normal">
    Does the lecturer’s planning meet the <b>requirements of the syllabus</b>?</span></template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.did_lecturer_planning_meet_requirements_of_syllabus">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field v-if="teacherReflectionObject.type_id===2" expanded>
            <template #label>
    <span class="has-text-weight-normal">
    Does the lecturer’s planning make sufficient reference to the syllabus <b>assessment criteria or learning objectives</b>?</span>
            </template>
            <b-select :disabled="edit" required expanded
                      v-model="teacherReflectionObject.did_planning_reference_assessment_or_objectives">
              <option :value="1">Yes</option>
              <option :value="0">No</option>
            </b-select>
          </b-field>
          <b-field position="is-centered" grouped>
            <b-field>
              <b-button @click.prevent="$emit('close')">
                {{ edit ? 'Close' : 'Cancel' }}
              </b-button>
            </b-field>
            <b-field>
              <b-button :loading="loading" v-if="!edit" native-type="submit" type="is-primary"
                        :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
              </b-button>
            </b-field>
          </b-field>
        </section>
      </PerfectScrollbarWrapper>
      <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
    </div>
  </form>
</template>

<script>

import TeacherReflection from "@/models/TeacherReflection";
import User from "@/models/User";
import UserRecord from "@/models/UserRecord";
import LessonPlan from "@/models/LessonPlan";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "TeacherReflectionForm",
  components: {PerfectScrollbarWrapper},
  data() {
    return {
      loading: false,
      loaded: false,
      otherText: null,
      good_students: [],
      bad_students: [],
      good_students_filter: '',
      bad_students_filter: '',
      good_student_comments: [],
      bad_student_comments: [],
      deleteComments: [],

      teacherReflectionObject: {
        type_id: this.type_id,
        lesson_plan_id: this.lesson_plan_id,
        were_teaching_methods_effective: null,
        were_learning_activities_effective: null,
        were_formative_assessment_strategies_used_throughout: null,
        were_instructional_materials_used_effectively: null,
        were_prior_knowledge_links_available: null,
        was_lesson_outcome_achieved: null,
        did_encourage_active_learning: null,
        were_differentiation_opportunities_available: null,
        were_students_allowed_to_reflect: null,
        collaboration_strategies: "",
        comment: null,
        next_steps: null,
        were_planning_documents_and_prep_sufficient: null,
        did_lecturer_planning_meet_requirements_of_syllabus: null,
        did_planning_reference_assessment_or_objectives: null
      }
    }
  },
  methods: {
    addGoodComment(students) {

      if (students.length > this.good_student_comments.length) {
        students.map(student => {
          if (!this.good_student_comments.some(comment =>
              comment.user_id === student.id
          )) {
            this.good_student_comments.push({
              comment: '',
              user_id: student.id,
              user: student,
              type_id: 1,
              admin_only: 0
            })
          }
        })
      } else {
        this.good_student_comments.map((comment, index) => {
          if (!students.some(student => student.id === comment.user_id)) {
            this.deleteComments.push(this.good_student_comments[index])
            this.good_student_comments.splice(index, 1)
          }
        })

      }
    }, addBadComment(students) {

      if (students.length > this.bad_student_comments.length) {
        students.map(student => {
          if (!this.bad_student_comments.some(comment =>
              comment.user_id === student.id
          )) {
            this.bad_student_comments.push({
              comment: '',
              user_id: student.id,
              user: student,
              type_id: 1,
              admin_only: 0
            })
          }
        })
      } else {
        this.bad_student_comments.map((comment, index) => {
          if (!students.some(student => student.id === comment.user_id)) {
            this.deleteComments.push(this.bad_student_comments[index])
            this.bad_student_comments.splice(index, 1)
          }
        })

      }
    },
    getFilteredGoodStudents(text) {
      this.good_students_filter = text
    }, getFilteredBadStudents(text) {
      this.bad_students_filter = text
    },
    submit() {
      if (this.edit) {
        return false
      } else {
        this.loading = true
        if (this.type_id === 1) {
          delete this.teacherReflectionObject.did_lecturer_planning_meet_requirements_of_syllabus
          delete this.teacherReflectionObject.did_planning_reference_assessment_or_objectives
          delete this.teacherReflectionObject.were_planning_documents_and_prep_sufficient
        }
        if (this.collaboration_other) {
          let hold = this.split_collaboration.filter(item => !item.includes('other-text:'))
          hold.push('other-text:' + this.otherText)
          this.split_collaboration = hold
        }
        TeacherReflection.Store(this.teacherReflectionObject).then(() => {
          let promises = []
          this.good_student_comments.map(comment => promises.push(UserRecord.Store(comment, {
            type: 'lesson-plans',
            id: this.lesson_plan_id
          })))
          this.bad_student_comments.map(comment => promises.push(UserRecord.Store(comment, {
            type: 'lesson-plans',
            id: this.lesson_plan_id
          })))
          Promise.all([promises]).then(() => {
            this.loading = false
            LessonPlan.update({where: this.lesson_plan_id, data: {teacher_reflection_count: 1}})
            this.$buefy.snackbar.open(`Reflection created!`)
            this.$emit('close')
          }).catch(err => {
            this.loading = false
            this.handleError(err)
          })

        }).catch(err => {
          this.handleError(err)
        })
      }
    }
  },
  computed: {
    good_students_computed() {
      return User.query().where(student => {
            let term = this.good_students_filter
            return student.first_name.toLowerCase().includes(term) || student.last_name.toLowerCase().includes(term) || student.email.toLowerCase().includes(term)
          }
      ).get()
    }, bad_students_computed() {
      return User.query().where(student => {
            let term = this.bad_students_filter
            return student.first_name.toLowerCase().includes(term) || student.last_name.toLowerCase().includes(term) || student.email.toLowerCase().includes(term)
          }
      ).get()
    },
    learners() {
      return User.all()
    },
    split_collaboration: {
      get() {
        return this.teacherReflectionObject.collaboration_strategies.split("~$")
      }, set(newVal) {
        this.teacherReflectionObject.collaboration_strategies = newVal.join("~$")
      }
    },
    collaboration_groupwork: {
      set(newVal) {
        if (newVal === true) {
          let settingArray = this.split_collaboration
          settingArray.push('Groupwork')
          this.split_collaboration = settingArray
        } else {
          let settingArray = this.split_collaboration
          settingArray = settingArray.filter(item =>
              item !== 'Groupwork'
          )
          this.split_collaboration = settingArray
        }
      },
      get() {
        if (this.split_collaboration.includes('Groupwork')) {
          return true
        }
        return false
      }
    }, collaboration_paired_work: {
      set(newVal) {
        if (newVal === true) {
          let settingArray = this.split_collaboration
          settingArray.push('Paired work')
          this.split_collaboration = settingArray
        } else {
          let settingArray = this.split_collaboration
          settingArray = settingArray.filter(item =>
              item !== 'Paired work'
          )
          this.split_collaboration = settingArray
        }
      },
      get() {
        if (this.split_collaboration.includes('Paired work')) {
          return true
        }
        return false
      }
    }, collaboration_think_pair_share: {
      set(newVal) {
        if (newVal === true) {
          let settingArray = this.split_collaboration
          settingArray.push('Think-pair-share')
          this.split_collaboration = settingArray
        } else {
          let settingArray = this.split_collaboration
          settingArray = settingArray.filter(item =>
              item !== 'Think-pair-share'
          )
          this.split_collaboration = settingArray
        }
      },
      get() {
        if (this.split_collaboration.includes('Think-pair-share')) {
          return true
        }
        return false
      }
    }, collaboration_group_discussions: {
      set(newVal) {
        if (newVal === true) {
          let settingArray = this.split_collaboration
          settingArray.push('Group discussions')
          this.split_collaboration = settingArray
        } else {
          let settingArray = this.split_collaboration
          settingArray = settingArray.filter(item =>
              item !== 'Group discussions'
          )
          this.split_collaboration = settingArray
        }
      },
      get() {
        if (this.split_collaboration.includes('Group discussions')) {
          return true
        }
        return false
      }
    },
    collaboration_other: {
      set(newVal) {
        if (newVal === true) {
          let settingArray = this.split_collaboration
          settingArray.push('Other')
          this.split_collaboration = settingArray
        } else {
          let settingArray = this.split_collaboration
          settingArray = settingArray.filter(item =>
              item !== 'Other'
          )
          this.split_collaboration = settingArray
        }
      },
      get() {
        if (this.split_collaboration.includes('Other')) {
          return true
        }
        return false
      }
    },


  },
  mounted() {
    if (!this.edit) {
      User.deleteAll()
      this.loading = true
      User.FetchLearners(this.lesson_plan_id).then(() => {

        this.loading = false
        this.loaded = true

      }).catch(err => {
        this.loading = false
        this.handleError(err)
        this.loaded = true
      })

    }
    if (this.edit) {
      this.loading = true
      this.teacherReflectionObject = this.teacherReflection
      UserRecord.FetchAllByModel({page: 1, limit: 999}, {}, ['user'], {
        type: 'lesson-plans',
        id: this.lesson_plan_id
      }).then(() => {
        this.good_student_comments = UserRecord.query().where('type_id', 1).with('user').get()
        this.good_student_comments.map(comment => this.good_students.push(comment.user))
        this.bad_student_comments = UserRecord.query().where('type_id', 2).with('user').get()
        this.bad_student_comments.map(comment => this.bad_students.push(comment.user))

        this.$nextTick(() => {
          if (this.collaboration_other) {
            let textArrayValueHold = this.split_collaboration.find(item => item.includes('other-text:'))
            if (textArrayValueHold) {
              this.otherText = textArrayValueHold.substring(10)
            }
          }
          this.loading = false
          this.loaded = true

        })
      }).catch(() => {
        this.loading = false
        this.loaded = true
      })

    }
  },
  props: {
    type_id: {
      type: Number,
      required: true
    },
    lesson_plan_id: {
      type: Number,
      required: true
    },
    teacherReflection: {
      type: Object,
      default() {
        return {
          name: '', ordinality: 0
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

