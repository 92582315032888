<template>
  <form @submit.prevent="submit" class="is-relative">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">Manage Assessment Grade Thresholds</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field v-if="editHold.length===0">
          No grade thresholds
        </b-field>
        <b-field expanded>
          <div class="table-container has-text-centered">

            <table v-if="editHold.length>0" class="table is-bordered is-striped is-expanded"
                   style="margin-left:auto;margin-right: auto;">
              <thead>
              <tr class="table-input">
                <th>Symbol</th>
                <th>Minimum Total</th>
                <th>Maximum Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(threshold,index) in editHold" :key="threshold.id">
                <td>
                  <b-field expanded>
                    <b-input expanded
                             :disabled="!canEdit"
                             required
                             autocomplete="off"
                             placeholder="Symbol"
                             v-model="threshold.symbol"
                             name="symbol"
                    />
                  </b-field>
                </td>
                <td>
                  <b-field expanded>
                    <b-numberinput expanded name="lowest"
                                   :disabled="!canEdit"
                                   step="0.5"
                                   :controls="false"
                                   required v-model="threshold.lowest"></b-numberinput>

                  </b-field>
                </td>
                <td>
                  <b-field expanded>
                    <b-numberinput expanded name="highest"
                                   :disabled="!canEdit"
                                   step="0.5"
                                   :controls="false"
                                   required v-model="threshold.highest"></b-numberinput>

                  </b-field>
                </td>


                <td>
                  <b-field
                      v-if="canDelete"
                  >

                    <b-button @click.prevent.stop="startDeleteGradeThreshold(threshold,index)"
                              type="is-danger">Remove
                    </b-button>
                  </b-field>
                </td>

              </tr>
              </tbody>
            </table>
            <b-field position="is-centered" grouped>
              <b-field v-if="editHold.length>0 && canEdit" position="is-centered"
              >
                <b-button native-type="submit" type="is-primary">
                  Save
                </b-button>
              </b-field>
              <b-field position="is-centered"
                       v-if="canCreate">
                <b-button
                    type="is-primary" outlined
                    @click.prevent="startCreateGradeThreshold()"
                >Add Grade
                </b-button>
              </b-field>
            </b-field>
          </div>
        </b-field>

      </section>
<!--      <footer :class="{'modal-card-foot':inModal}">-->
<!--        <button :disabled="!canEdit" class="button"-->
<!--                type="is-primary"-->
<!--                :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save-->
<!--        </button>-->
<!--      </footer>-->
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import AssessmentGradeThreshold from "@/models/AssessmentGradeThreshold";

export default {
  name: "AssessmentThresholdsForm",
  data() {
    return {
      loading: false,
      dataHold: null,
      editHold: []
    }
  },
  computed: {

    grade_thresholds() {
      if (this.dataHold === null) {
        return AssessmentGradeThreshold.query().where('assessment_id', this.assessment_id).orderBy('highest', 'desc').get()
      } else {
        return this.dataHold
      }
    },
  },
  methods: {
    getGradeThresholds() {
      this.loading = true
      this.dataHold = this.grade_thresholds
      AssessmentGradeThreshold.deleteAll()
      return AssessmentGradeThreshold.FetchAll({
        page: 1,
        limit: 99
      }, [], this.assessment_id).then(() => {
        this.dataHold = null
        this.editHold = this.grade_thresholds
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })
    },
    submit() {

      this.loading = true

      let promises = []
      this.editHold.map(threshold => {
        if (Object.prototype.hasOwnProperty.call(threshold, 'id')) {
          promises.push(AssessmentGradeThreshold.Update(threshold))
        } else {
          delete threshold.id
          AssessmentGradeThreshold.Store(threshold)
        }
      })

      Promise.all(promises).then(() => {
        this.getGradeThresholds().then(() => {
          this.$buefy.snackbar.open('Grade Thresholds updated!')

        })
      }).then(() => {
        this.$emit('close')

      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })
    },
    startCreateGradeThreshold() {
      this.editHold.push({
        symbol: '',
        lowest: null,
        highest: null,
        assessment_id: this.assessment_id
      })
    },
    startDeleteGradeThreshold(threshold, index) {
      if (!Object.prototype.hasOwnProperty.call(threshold, 'id')) {
        return this.editHold.splice(index, 1)
      }
      this.$buefy.dialog.confirm({
        title: "Deleting Grade Threshold",
        message:
            "Are you sure you want to <b>delete</b> this grade threshold? This action cannot be undone.",
        confirmText: "Delete Grade Threshold",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteGradeThreshold(threshold, index),
      });
    },
    deleteGradeThreshold(threshold, index) {
      this.$store.dispatch("loader/show");
      AssessmentGradeThreshold.Delete(threshold).then(
          () => {
            this.editHold.splice(index, 1)
            this.$buefy.snackbar.open('Grade Threshold deleted!')
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      ).finally(() => {
        this.$store.dispatch("loader/hide");

      })

    },
  }
  ,
  mounted() {
    this.getGradeThresholds()
    if (this.inModal) {
      this.makeModalLarge()

    }
  }
  ,
  props: {

    assessment_id: {
      type: Number,
      required: true

    },

    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    , canCreate: {
      type: Boolean,
      default
          () {
        return false
      }
    }, canDelete: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    , canEdit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

