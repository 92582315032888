<template>
  <div class="box">
    <div class="is-flex is-justify-content-space-between is-align-items-center is-clickable"
         @click.prevent.stop="isOpen= !isOpen"><p class="has-text-weight-semibold is-size-4">
      {{ objective.reference }}</p>
      <b-tooltip :label="isOpen?'collapse':'expand'" size="is-small"
                 type="is-primary">
        <a
            class="card-header-icon has-text-link px-0 py-0"

        >
          <b-icon :icon="isOpen?'menu-up':'menu-down'" size="is-medium"/>
        </a>
      </b-tooltip>
    </div>
    <b-field v-if="isOpen" label="Content">
      <div class="inline-objective-content">
        <ckeditor disabled :editor="editor"
                  :value="objective.name"
                  :config="emptyEditorConfig"></ckeditor>
      </div>
    </b-field>
    <b-field v-if="objective.recommended_activities && isOpen" label="Recommended Activities and Resources">
      <div class="inline-objective-content">
        <ckeditor disabled :editor="editor"
                  :value="isOpen?objective.recommended_activities: clipDescription(objective.recommended_activities)"
                  :config="emptyEditorConfig"></ckeditor>
      </div>
    </b-field>
    <b-table
        v-if="media.length>0"
        class="margin-top"
        :data="media"
        :striped="true"
        :hoverable="true"
        :bordered="true"
    >
      <template #empty>
        <div class="has-text-centered">No Media</div>
      </template>
      <b-table-column v-slot="props"

                      sortable
                      field="filename"
                      label="Filename"
      >{{ props.row.filename }}
      </b-table-column
      >


      <b-table-column v-slot="props"
                      sortable
                      field="caption"
                      label="Caption"
      >{{
          props.row.caption
        }}
      </b-table-column
      >

      <b-table-column v-slot="props" width="170" custom-key="actions">
        <b-field grouped>
          <b-field>
            <b-button
                tag="a"
                :href="props.row.temporary_url"
                type="is-primary"
                target="_blank">View
            </b-button>

          </b-field>
        </b-field>

      </b-table-column>
    </b-table>

    <b-loading :is-full-page="false" :can-cancel="true" v-model="isLoading"></b-loading>
  </div>

</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {mathEditorConfig} from "@/mathEditorConfig";
import clip from "text-clipper";
import Media from "@/models/Media";

export default {
  name: 'ObjectiveLineItem',
  data() {
    return {
      editor: ClassicEditor,
      emptyEditorConfig: {
        ...{plugins: mathEditorConfig.plugins, toolbar: []},
        ...{placeholder: "Objective body"},

      },
      isLoading: true,

      isOpen: false
    }
  },
  computed: {
    media() {
      return Media.query().where('mediable_type', 'objectives').where('mediable_id', this.objective.id).get()
    }
  },
  mounted() {
    this.isLoading = true
    Media.FetchAll({page: 1, limit: 30}, {modelName: 'objectives', model: this.objective.id}).then(() => {
      this.isLoading = false
    }).catch(err => {
      this.isLoading = false
      this.handleError(err)
    })
  },
  methods: {
    clipDescription(text) {
      return clip(text, 300, {
        html: true,
      });
    },
  }, props: {
    objective: {
      type: Object,
      required: true
    }
  }
}
</script>
