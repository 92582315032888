<template>
  <div>
    <CampusHeader
        :activeTab="activeTab"
        :can-all-class-groups="true"
        :can-create="canCreate"
        :can-select="activeTab===0"
        :can-select-class-groups="activeTab!==0"
        :can-select-year="true"
        :class-group-subject-id="selectedSubject?selectedSubject.id:null"
        :create-text="createText"
        :header-text="headerText"
        :header_tabs="header_tabs"
        :hide-tabs="true"
        :manage-campuses="true"
        :manage-class-groups="activeTab!==0"
        :show-mobile="false"
        @start-creating="startCreate()"
        @change-tab="changeTab"
    ></CampusHeader>
    <section class="section pt-4 box">
      <b-tabs v-model="activeTab" :class="{'hidden-tabs':header_tabs.length<2}" :destroy-on-hide="true" class=""
              expanded
              multiline @input="changeTab">
        <b-tab-item v-if="useTab('subjects')" label="Subjects">
          <div class=" columns">
            <div class="column is-12">
              <SubjectsIndex
                  @selected="option => handleSubjectSelect(option)"
              >
              </SubjectsIndex>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item
            v-if="useTab('lesson-plans') && !!$store.getters['entities/user-permissions/find']('view lesson-plans')"
            label="Lesson Plans">
          <div class=" columns">
            <div class="column is-12">
              <LessonPlansIndex :can-delete="!!$store.getters['entities/user-permissions/find']('delete lesson-plans')"
                                :selected_subject="selectedSubject"></LessonPlansIndex>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('lessons') && !!$store.getters['entities/user-permissions/find']('view lesson-plans')"
                    label="Lessons">
          <div class=" columns">
            <div class="column is-12">
              <CardIndex
                  :board_title="'Lessons'"
                  :default-class-group-id="parseInt(this.$store.state.campus.selected_class_group_id)"
                  :subject_id="selectedSubject.id"
                  :reload-bit-flip="lessonBitFlip"
                  :team_permission="'view lesson-plans'"
                  @flipped="lessonBitFlip=false"

              />
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('progress') && !!$store.getters['entities/user-permissions/find']('view lesson-plans')"
                    label="Progress Tracker">
          <div class=" columns">
            <div class="column is-12">
              <ProgressTable
                  :default-class-group-id="parseInt(this.$store.state.campus.selected_class_group_id)"
                  :default-subject-id="selectedSubject.id"
              />

            </div>
          </div>
        </b-tab-item>
        <b-tab-item
            v-if="useTab('assessments') && !!$store.getters['entities/user-permissions/find']('view assessments')"
            label="Assessments">
          <div class=" columns">
            <div class="column is-12">
              <AssessmentsIndex
                  :can-create="!!$store.getters['entities/user-permissions/find']('create assessments')"
                  :can-delete="!!$store.getters['entities/user-permissions/find']('delete assessments')"
                  :can-edit="!!$store.getters['entities/user-permissions/find']('edit assessments')"
                  :selected_subject="selectedSubject"
                  @selected="option => handleAssessmentSelect(option)"></AssessmentsIndex>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('marksheet') && !!$store.getters['entities/user-permissions/find']('view grades')"
                    label="Marksheet">
          <div class=" columns">
            <div class="column is-12">
              <Marksheet :selected_assessment="selectedAssessment" :selected_subject="selectedSubject"></Marksheet>
            </div>
          </div>
        </b-tab-item>

      </b-tabs>
    </section>
  </div>
</template>

<script>

import CampusHeader from "@/components/panelled-dash/CampusHeader";


import SubjectsIndex from "@/components/lessonPlans/SubjectsIndex";
import LessonPlansIndex from "@/components/lessonPlans/LessonPlansIndex";
import LessonPlanForm from "@/components/lessonPlans/LessonPlanForm";
import AssessmentForm from "@/components/assessments/AssessmentForm";
import AssessmentsIndex from "@/components/lessonPlans/AssessmentsIndex";
import Marksheet from "@/components/lessonPlans/Marksheet";
import ClassGroup from "@/models/ClassGroup";
import Subject from "@/models/Subject";
import CardIndex from "@/components/lessons/CardIndex";
import ProgressTable from "@/components/progressTracker/ProgressTable";
import LessonForm from "@/components/lessons/LessonForm";
// import Enrolment from "@/models/Enrolment";

export default {
  name: "lessonPlanning",
  components: {
    SubjectsIndex,
    CampusHeader,
    LessonPlansIndex,
    AssessmentsIndex,
    Marksheet,
    CardIndex,
    ProgressTable
  },
  data() {
    return {
      tabs: [{name: 'Subjects', role: 'subjects'}],
      header_tabs: [{title: 'Subjects'}],
      loaded: false,
      activeTab: 0,
      selectedSubject: null,
      selectedAssessment: null,
      search: null,
      loadingFilter: false,
      userDataHold: null,
      meta: Object,
      page: 1,
      limit: 20,
      loadingData: false,
      searchHold: null,
      lessonBitFlip:false
    };
  },


  methods: {
    startCreate() {
      if (this.activeTab === 1) {
        this.startCreatePlan()
      }
      if (this.activeTab === 2) {
        this.startCreateLesson()
      }
      if (this.activeTab === 3) {
        this.startCreateAssessment()
      }
    },
    startCreatePlan() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          defaultDate: this.$store.state.attendance.attendance_date_truth,
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: false,
          course_scope_id: this.selectedSubject.course_scope_id,
          inModal: true,
          subject_id: this.selectedSubject.id
        },
        component: LessonPlanForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    }, startCreateLesson() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          edit: false,
          class_group_id: this.$store.state.campus.selected_class_group_id,
          inModal: true,
          subject_id: this.selectedSubject.id
        },
        component: LessonForm,
        fullScreen: false,
        trapFocus: true,
        events: {
          "submitted": () => {
            console.log('ssss')
            this.lessonBitFlip = true
          },
        },
        canCancel: ['outside', 'x']
      });
    },

    startCreateAssessment() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          campus_id: this.$store.state.campus.selected_campus_id,
          subject_id: this.selectedSubject.id,
          edit: false,
          course_scope_id: this.selectedSubject.course_scope_id,
          inModal: true,
          manageTerms: true,
          canCreate: !!this.$store.getters['entities/user-permissions/find']('create assessments'),
          canEdit: !!this.$store.getters['entities/user-permissions/find']('edit assessments')
        },
        component: AssessmentForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });
    },
    handleSubjectSelect(subject, navigate = true) {
      if (this.$store.getters['entities/user-permissions/find']('view lesson-plans') && !this.tabs.some(tab => tab.name === 'Lesson Plans')) {
        this.tabs.push({name: 'Lesson Plans', role: 'lesson-plans'})
        this.header_tabs.push({title: 'Lesson Plans'})
      }
      if (this.$store.getters['entities/user-permissions/find']('view lesson-plans') && !this.tabs.some(tab => tab.name === 'Lessons')) {
        this.tabs.push({name: 'Lessons', role: 'lessons'})
        this.header_tabs.push({title: 'Lessons'})
      }
      if (this.$store.getters['entities/user-permissions/find']('view lesson-plans') && !this.tabs.some(tab => tab.name === 'Progress Tracker')) {
        this.tabs.push({name: 'Progress Tracker', role: 'progress'})
        this.header_tabs.push({title: 'Progress Tracker'})
      }
      if (this.$store.getters['entities/user-permissions/find']('view assessments') && !this.tabs.some(tab => tab.name === 'Assessments')) {
        this.tabs.push({name: 'Assessments', role: 'assessments'})
        this.header_tabs.push({title: 'Assessments'})
      }
      if (this.$store.getters['entities/user-permissions/find']('view grades') && !this.tabs.some(tab => tab.name === 'Marksheet')) {
        this.tabs.push({name: 'Marksheet', role: 'marksheet'})
        this.header_tabs.push({title: 'Marksheet'})
      }
      this.selectedSubject = subject
      return ClassGroup.FetchAll({page: 1, limit: 999}, {subject_id: subject.id}).then(() => {
        this.setQuery({subject_id: subject.id})
        this.$nextTick(() => {
          if (navigate) {
            this.changeTab(this.tabs.findIndex(tab => tab.name === 'Lesson Plans'))
          }
        })
      }).catch(err => {
        this.handleError(err)
      })


    }, handleAssessmentSelect(assessment) {
      this.setQuery({assessment_id: assessment.id})
      this.selectedAssessment = assessment
      this.$nextTick(() => {
        this.changeTab(this.tabs.findIndex(tab => tab.name === 'Assessments'))
      })
    },
    changeTab(index) {
      this.activeTab = index;
      this.setQuery({tab: this.tabs[index].role})
    },
    goToTabName(tabName) {
      this.activeTab = this.tabs.findIndex(tab => {
        return tab.role === tabName
      })

      this.setQuery({tab: tabName})

    },
    useTab(role) {
      return this.tabs.some((tab) => tab.role === role);
    },

  },

  computed: {
    createText() {
      if (this.activeTab === 1) {
        return 'New Plan'
      }
      if (this.activeTab === 2) {
        return 'New Lesson'
      }
      if (this.activeTab === 4) {
        return 'New Assessment'
      }
      return ''
    },
    canCreate() {
      if (this.activeTab === 1 && this.$store.getters['entities/user-permissions/find']('create lesson-plans')) {
        return true
      }
      if (this.activeTab === 2 && this.$store.getters['entities/user-permissions/find']('create lesson-plans')) {
        return true
      }
      return false
    },
    headerText() {
      if (this.selectedSubject === null) {
        return 'My Subjects'
      }
      return `${this.selectedSubject.name}`
    }


  },
  mounted() {
    if (this.$route.query.subject_id) {
      Subject.FetchById(parseInt(this.$route.query.subject_id), ['course_scope', 'course_scope.learning_stream']).then(() => {
        this.$nextTick(() => {
          this.handleSubjectSelect(Subject.query().whereId(parseInt(this.$route.query.subject_id)).withAllRecursive().first(), false).then(() => {
            if (this.$route.query.tab) {

              if (this.useTab(this.$route.query.tab)) {
                if (this.$route.query.tab === 'lesson-plans') {
                  this.goToTabName('lesson-plans')
                }
                if (this.$route.query.tab === 'lessons') {
                  this.goToTabName('lessons')
                }
                if (this.$route.query.tab === 'assessments') {

                  this.goToTabName('assessments')

                }
                if (this.$route.query.tab === 'progress') {

                  this.goToTabName('progress')

                }
                if (this.$route.query.tab === 'marksheet') {
                  this.goToTabName('marksheet')
                }
              } else {
                this.removeQuery(['tab'])
              }
            }
          })

        })
      }).catch(err => {
        this.handleError(err)
        this.removeQuery(['subject_id'])
      })

    }


  },
};
</script>
